import { Box, Flex, Icon } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

const NavItem = ({ icon, children, isActive, link, expand, ...rest }) => {
    return (
        <Box
            as={NavLink}
            to={link}
            exact
            style={{ textDecoration: 'none' }}
            _focus={{ boxShadow: 'none' }}
            _activeLink={{ color: '#f1613f' }}
            {...rest}
        >
            <Flex
                fontFamily="Telegraf"
                fontSize={isActive ? '16px' : '14px'}
                lineHeight="19.11px"
                fontWeight={400}
                align="center"
                p="4"
                mx="4"
                borderRadius="lg"
                role="group"
                cursor="pointer"
                color={isActive ? '#f1613f' : 'inherit'}
                _hover={{
                    bg: 'linear-gradient(180deg, rgba(241, 97, 63, 0.05) 0%, rgba(241, 97, 63, 0.1) 100%)',
                    color: '#636260',
                }}
            >
                {icon && (
                    <Icon
                        mr="4"
                        fontSize="16"
                        _groupHover={{ color: '#636260' }}
                        as={icon}
                    />
                )}
                {expand ? children : null}
            </Flex>
        </Box>
    );
};

export default NavItem;
