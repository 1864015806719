import React, { useMemo, useState } from "react";
import "./admintable.css";
import filtersvg from "../../assets/icons/filter_up_down.svg";
import AdminTransactionLoader from "../Loader/AdminTransactionLoader/AdminTransactionLoader";
import moment from "moment";
import SearchIcon from "../AdminTransactionTable/searchIcon";
import Label from "../Label/Label";
import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import PaginationComponent from "../Pagination/PaginationComponent";
import { MdClose } from "react-icons/md";
import { useMutation } from "@apollo/client";
import {
  ADMIN_UPDATE_ACCOUNT_STATUS,
  VERIFY_BROADCASTER_EMAIL,
} from "../GraphQL/Mutation";
import { toast } from "react-toastify";
import { GET_RADIOSTATIONS_FOR_ADMIN } from "../GraphQL/Queries";

const AdminTable = ({
  data,
  header,
  loading,
  refetch,
  showFilter,
  handleNavigate,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [entries, setEntries] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [status, setStatus] = useState(false);

  useMemo(() => {
    const isStatus = data[0]?.hasOwnProperty("status");
    if (isStatus) setStatus(true);
  }, [data]);

  const handlePagination = (action) => {
    if (action === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (action === "next" && data.length > currentPage * entries) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset current page when searching
  };

  const filteredData = data?.filter((item) =>
    Object.values(item).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const totalPages = Math.ceil(filteredData.length / entries);

  const paginatedData = filteredData?.slice(
    (currentPage - 1) * entries,
    currentPage * entries
  );

  const renderPageNumbers = () => {
    const pageNumbers = [];

    for (let i = 1; i <= totalPages; i++) {
      // Display first two pages
      if (i <= 2) {
        pageNumbers.push(
          <span
            key={i}
            className={i === currentPage ? "active" : ""}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </span>
        );
      }
      // Display ellipsis for middle section
      else if (i > 2 && i < totalPages - 1 && Math.abs(i - currentPage) > 1) {
        // Display ellipsis only once in the middle section
        if (pageNumbers[pageNumbers.length - 1] !== ".") pageNumbers.push(" .");
      }
      // Display last two pages
      else if (i >= totalPages - 1) {
        pageNumbers.push(
          <span
            key={i}
            className={i === currentPage ? "active" : ""}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </span>
        );
      }
      // Display current and adjacent pages in the middle section
      else {
        pageNumbers.push(
          <span
            key={i}
            className={i === currentPage ? "active" : ""}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </span>
        );
      }
    }

    return pageNumbers;
  };

  return (
    <>
      {loading ? (
        <AdminTransactionLoader />
      ) : (
        <div className="admin_table_wrapper">
          <section className="filter_entry_wrapper">
            <div className="filter_entry_dropdown">
              <span>Show</span>
              <select
                name=""
                id=""
                value={entries}
                onChange={(e) => setEntries(parseInt(e.target.value))}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
              </select>
              <span>entries</span>
            </div>
            <div className="search_input_wrapper">
              <InputGroup maxW="250px" bg="#EFF6F4">
                <InputRightElement cursor="pointer" py={4}>
                  <SearchIcon />
                </InputRightElement>
                <Input
                  type="text"
                  placeholder="Search"
                  onChange={handleSearch}
                />
              </InputGroup>
            </div>
          </section>
          <section className="table_container">
            <div className="table_wrapper">
              <TableContainer>
                <Table variant="simple" id="data-table">
                  <Thead>
                    <Tr
                      position="sticky"
                      top="0"
                      zIndex="1"
                      background="#EFF6F4"
                      fontSize="14px"
                    >
                      {header?.map((tableHeader, index) => (
                        <Th key={index}>
                          <Box display="flex" alignItems="center" gap={4}>
                            <Box as="span">{tableHeader.title}</Box>
                            {showFilter && (
                              <Box as="span" cursor="pointer">
                                <img src={filtersvg} alt="filter-icon" />
                              </Box>
                            )}
                          </Box>
                        </Th>
                      ))}
                      {status && (
                        <Th>
                          <Box display="flex" alignItems="center" gap={4}>
                            <Box as="span">Status</Box>
                            {showFilter && (
                              <Box as="span" cursor="pointer">
                                <img src={filtersvg} alt="filter-icon" />
                              </Box>
                            )}
                          </Box>
                        </Th>
                      )}
                    </Tr>
                  </Thead>
                  <Tbody className="t">
                    {paginatedData?.length !== 0 ? (
                      paginatedData?.map((columnData, index) => (
                        <Box>
                          <Tr
                            borderBottom={
                              index !== data?.length - 1
                                ? "0.5px solid #ABA8A8"
                                : "none"
                            }
                            borderColor="#ABA8A8"
                            key={columnData?.id}
                          >
                            <Td
                              color="#F99B28"
                              borderBottom="0.5px solid #ABA8A8"
                              onClick={() => handleNavigate(columnData.id)}
                            >
                              <span style={{ cursor: "pointer" }}>
                                {columnData?.station}
                              </span>
                            </Td>
                            <Td>{columnData?.email}</Td>
                            <Td>{columnData?.phone}</Td>
                            <Td>
                              {moment(columnData?.date).format(
                                "YYYY-MM-DD HH::mm::ss"
                              )}
                              {columnData?.published && (
                                <p>{columnData?.published}</p>
                              )}
                            </Td>
                            {columnData.status && (
                              <Td>
                                <UpdateRadioStationStatus
                                  label={columnData.status}
                                  data={columnData}
                                />
                              </Td>
                            )}
                          </Tr>
                        </Box>
                      ))
                    ) : (
                      <Box className="admin_not_found">No Data</Box>
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
            </div>

            <PaginationComponent
              currentPage={currentPage}
              entries={entries}
              filteredDataLength={filteredData}
              handlePagination={handlePagination}
              renderPageNumbers={renderPageNumbers}
            />
          </section>
        </div>
      )}
    </>
  );
};

export default AdminTable;

const UpdateRadioStationStatus = ({ label, data }) => {
  const [approveLoading, setApproveLoading] = useState(false);
  const [verified, setVerified] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [updateRadioStationStatus] = useMutation(ADMIN_UPDATE_ACCOUNT_STATUS, {
    refetchQueries: [
      { query: GET_RADIOSTATIONS_FOR_ADMIN },
      "GetAllBroadcasters",
    ],
  });
  const [verifyBroadcasterEmail, { loading: verifyLoader }] = useMutation(
    VERIFY_BROADCASTER_EMAIL,
    {
      variables: {
        verifyBroadcasterEmailId: data.id,
      },
      onCompleted: () => {
        setVerified(true);
      },
      onError: (err) => {
        toast.error(err);
        onClose();
      },
    }
  );

  const handleApproveTransactionUpdate = async (status) => {
    setApproveLoading(true);
  
    try {
      await updateRadioStationStatus({
        variables: {
          userId: data.id.toString(),
          status,
        },
        onCompleted: (data) => {
          toast.success("Updating user status successful");
        },
        onError: (err) => {
          toast.error("Updating user status Failed");
        },
      });
    } finally {
      setApproveLoading(false);
      onClose();
    }
  };
  

  return (
    <>
      <Button
        width="fit-content"
        variant="link"
        bg="transparent"
        onClick={onOpen}
      >
        <Label label={label} />
      </Button>
      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent padding="20px">
          <div className="popover_wrapper">
            <ModalHeader>
              <div className="popover_head">
                <div className="popover_title">Update Status</div>
                <div onClick={onClose} style={{cursor: "pointer"}}>
                  <MdClose className="popover_closebtn" />
                </div>
              </div>
            </ModalHeader>

            <ModalBody>
              <div className="popover_content">
                {data.isEmailVerified === false && (
                  <Flex justifyContent="flex-end" w="100%">
                    <Button
                      onClick={verifyBroadcasterEmail}
                      bg="#004643"
                      color="#fff"
                      _hover={{ opacity: 0.8 }}
                    >
                      {verifyLoader ? (
                        <>
                          <Spinner size="sm" color="teal.500" /> verifying
                        </>
                      ) : verified === true ? (
                        "Verified"
                      ) : (
                        "Verify Email"
                      )}
                    </Button>
                  </Flex>
                )}
                <div className="sub_content">
                  <div>
                    <p>Radio Station:</p>
                  </div>
                  <div className="popover_data">
                    <p className="popover_data">{data.station || "----"}</p>
                  </div>
                </div>

                <div className="sub_content">
                  <div>
                    <p>Email:</p>
                  </div>
                  <div>
                    <p className="popover_data">{data.email || "----"}</p>
                  </div>
                </div>
                <div className="sub_content">
                  <div>
                    <p>Phone:</p>
                  </div>
                  <div>
                    <p className="popover_data">{data.phone || "----"}</p>
                  </div>
                </div>
                <div className="sub_content">
                  <div>
                    <p>Date:</p>
                  </div>
                  <div>
                    <p className="popover_data">
                      {moment(data.date).format("YYYY-MM-DD HH:mm:ss") ||
                        "----"}
                    </p>
                  </div>
                </div>
                <div className="sub_content">
                  <div>
                    <p>Status:</p>
                  </div>
                  <div>
                    <Label label={label || "unknown"} />
                  </div>
                </div>
              </div>
              {/* )} */}
            </ModalBody>
            <ModalFooter>
              <div className="popup_footer">
                <div style={{ color: "#000" }} className="cancel">
                  No, Cancel
                </div>
                <div
                  className="approve"
                  onClick={() =>
                    handleApproveTransactionUpdate(
                      label === "Active" ? false : true
                    )
                  }
                >
                  {approveLoading ? (
                    <Spinner size="sm" color="teal.500" />
                  ) : label === "Active" ? (
                    "Deactivate"
                  ) : (
                    "Activate"
                  )}
                </div>
              </div>
            </ModalFooter>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
};
