import "./css/ourTeam.css";
import heroImg from "../transmitAssets/ourTeamHero.svg";
import TransmitNavbar from "../components/TransmitNavbar/Navbars";
import {
  Card,
  CardHeader,
  CardBody,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";

const teamInfo = [
  {
    image: "/images/Tj.png",
    name: "Tunji Idowu",
    pos: "Founder",
  },
  {
    image: "/images/mr-femi.png",
    name: "Femisire Ajayi",
    pos: "Chief Executive Officer",
  },
  {
    image: "/images/mr-biyi.png",
    name: "Biyi Akangbe",
    pos: "Chief Technology Officer",
  },
  {
    image: "/images/Hodo.png",
    name: "Hodo",
    pos: "HR/Admin",
  },
  {
    image: "/images/hope.png",
    name: "Hope Mgbebuihe",
    pos: "Product Manager",
  },
  {
    image: "/images/austine.png",
    name: "Austine Okwuokei",
    pos: "Snr. Executive Marketing",
  },
  {
    image: "/images/Robert.png",
    name: "Victory Robert",
    pos: "Head of Digital Marketing",
  },
  // {
  //   image: "/images/Lanre.jpg",
  //   name: "Lanre Awoyemi",
  //   pos: "Marketing Consultant",
  // },
  {
    image: "/images/Adams.png",
    name: "Adams",
    pos: "Creative Director",
  },
  {
    image: "/images/Jelilat.png",
    name: "Jelilat Babatunde",
    pos: "Executive Marketing",
  },
  {
    image: "/images/Funmi.jpg",
    name: "Funmi Omowo",
    pos: "Marketing Intern",
  },
  {
    image: "/images/Ugo.jpg",
    name: "Oradiegwu Christopher",
    pos: "Marketing Intern",
  },
];

const OurTeam = () => {
  return (
    <>
      <div className="ourTeam">
        <TransmitNavbar />
        <div className="ourTeamWrapper">
          <div className="ourTeamTop">
            <div className="ourTeamTopLeft">
              <svg
                width="100%"
                //   height="492"
                viewBox="0 0 586 492"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="ourTeamHeroSvgBackground"
              >
                <path
                  d="M68.4311 112.623C129.464 56.0371 216.311 0 262.245 0C308.179 0 393.164 132.251 422.73 153.096C452.296 173.942 576.221 253.682 583.272 302.673C596.189 392.419 562.974 461.048 489.161 466.328C415.349 471.607 336.001 476.185 232.664 488.324C129.326 500.464 104.415 478.646 104.415 478.646C104.415 478.646 66.5859 461.048 25.9891 367.783C-14.6077 274.517 -12.7624 187.9 68.4311 112.623Z"
                  fill="#FFEAC7"
                />
              </svg>

              <img src={heroImg} alt="hero" className="ourTeamHeroImg" />
            </div>

            <div className="ourTeamTopRight">
              <h3 className="ourTeamTopRightHeader">ABOUT US</h3>
              <h1 className="ourTeamTopRightTitle">
                Meet the Team Behind Transmit
              </h1>
              {/* <p className="ourTeamTopRightContent">
                D
                <span className="lower-text">
                  iscover the experts behind Transmit who bring the magic to
                  life. Whether it's crafting compelling radio ads, or utilizing
                  cutting-edge AI algorithms to track and optimize your
                  campaigns, our team possesses the skills and expertise to help
                  you succeed.
                </span>
              </p>
              <Link to="/let's-talk"><button className="scheduleCallBtn">  Schedule a Call</button></Link> */}
            </div>
          </div>
        </div>
        <div className="ourTeamBottom">
          <h1 className="ourTeamProfileHeader">
            m<span className="lower-text">eet our team</span>
          </h1>
          <div className="ourTeamCardWrappper">
            {teamInfo.map(({image, name, pos}) => (
            <Card>
            <CardHeader>
              <img
                src={image}
                alt="ceo"
                className="teamImage"
              />
            </CardHeader>
            <CardBody>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <h1 className="teamName">{name}</h1>
                <p className="teamPosition">{pos}</p>
              </div>
            </CardBody>
          </Card>
            ))}

          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default OurTeam;
