import { Flex, IconButton, Text, Button, useDisclosure, useColorModeValue, Box } from '@chakra-ui/react';
import { HamburgerIcon, HelpIcon,NotificationIcon, Logo } from '../menuItems';

const MobileNav = ({ onOpen, ...rest }) => {
    return (
        <Flex
            flexDirection="row-reverse"
            px={{ base: 4, md: 4 }}
            height="20"
            alignItems="center"
            bg={{ base: 'black', md: useColorModeValue('white', 'gray.900') }}
            borderBottomWidth="1px"
            borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
            justifyContent={{ base: 'space-between', md: 'flex-end' }}
            borderRadius={{ base: '0 0 24px 24px', md: '0 0 0 0' }}
            {...rest}
        >
           
            <IconButton
                display={{ base: 'flex', md: 'none' }}
                onClick={onOpen}
                variant="none"
                aria-label="open menu"
                icon={<HamburgerIcon />}
            />
            <Text
                display={{ base: 'flex', md: 'none' }}
                fontSize="2xl"
                fontFamily="monospace"
                fontWeight="bold"
            >
                <Logo  isMobile />
            </Text>
            <Flex spacing={{ base: '0', md: '6' }} w={{ md: '100%' }} justifyContent={{ md: 'flex-end' }} alignItems="center" display={{ base: 'none', md: 'flex' }} gap="20px">
                <Button padding="15px 30px" borderRadius="27px" bg="#F1613F" color="white">New Campaign</Button>
                <Button variant="none"><NotificationIcon /></Button>
                <Button variant="none"><HelpIcon /></Button>
            </Flex>
            <Box display={{md: 'none'}}></Box>
        </Flex>
    );
};

export default MobileNav;
