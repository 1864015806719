import { useState, useEffect } from 'react';
import { Box, Flex, CloseButton, useColorModeValue } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import NavItem from './NavItem';
import { linkItems, Logo, LogoHover } from '../menuItems';

const SidebarContent = ({ onClose, ...rest }) => {
    const [expand, setExpand] = useState(false);
    const [windowSize, setWindowSize] = useState(window.innerWidth);
    const location = useLocation();
    const activeItem = location.pathname;

    useEffect(() => {
        const handleResize = () => setWindowSize(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <Box
            onMouseEnter={() => setExpand(true)}
            onMouseLeave={() => setExpand(false)}
            transition="width 1s ease"
            bg={useColorModeValue('white', 'gray.900')}
            borderRight="1px"
            borderRightColor={useColorModeValue('gray.200', 'gray.700')}
            w={{ base: 'full', md: expand ? '256px' : '100px' }}
            pos="fixed"
            zIndex="99"
            h="full"
            {...rest}
        >
            <Flex h="20" alignItems="center" mx="auto" justifyContent="space-between">
                <Box display={{ base: 'block', md: 'none' }}></Box>
                <Box p={expand ? '48px 24px 24px' : '0 24px'} w="100%" borderBottom={{ md: '0.5px solid #DADADA' }}>
                    {expand ? <LogoHover /> : <Logo />}
                </Box>
                <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} mr={4} boxSize={10}/>
            </Flex>
            <Box pt="32px">
                {linkItems.map(({ name, icon, link }) => (
                    <NavItem
                        key={name}
                        icon={icon}
                        link={link}
                        expand={expand || windowSize < 768}
                        isActive={activeItem === link}
                    >
                        {expand || windowSize < 768 ? name : null}
                    </NavItem>
                ))}
            </Box>
        </Box>
    );
};

export default SidebarContent;
