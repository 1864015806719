import { gql } from "@apollo/client";

export const BROADCASTER_WITHDRAW = gql`
  mutation BroadcasterWithdraw($input: broadcasterWithdrawInput) {
    broadcaster {
      broadcasterWithdraw(input: $input)
    }
  }
`;

export const SAVE_BROADCASTER_BANK_ACCOUNT = gql`
  mutation SaveBroadcasterBankAccount(
    $input: saveBroadcasterBankAccountInputForBroadcaster
  ) {
    broadcaster {
      saveBroadcasterBankAccount(input: $input) {
        bankCode
        accountNumber
        accountName
        bankName
      }
    }
  }
`;

export const UPDATE_BROADCASTER_PROFILE = gql`
  mutation UpdateBroadcasterProfile($input: updateBroadcasterProfileInput) {
    broadcaster {
      updateBroadcasterProfile(input: $input) {
        broadcaster {
          email
          name
          fullname
          positionHeld
          address
          country
          state
          city
          zipcode
          radioStationWebsite
          radioStationCategory
          radioStationDigitalStreaUrl
          phoneNumber
          banner
          tagLine
        }
      }
    }
  }
`;

export const CREATE_VOLUME_DISCOUNT = gql`
  mutation CreateVolumeDiscount($input: createVolumeDiscountInput) {
    broadcaster {
      createVolumeDiscount(input: $input) {
        id
        userId
        discount
        fromAmount
        toAmount
      }
    }
  }
`;
export const UPDATE_CAMPAIGN_STATUS = gql`
  mutation UpdateCampaignStatus(
    $campaignId: ID!
    $campaignStatus: BookingStatus!
  ) {
    broadcaster {
      updateCampaignStatus(
        campaignId: $campaignId
        campaignStatus: $campaignStatus
      ) {
        message
      }
    }
  }
`;
export const ADVERTISER_REGISTER = gql`
  mutation AdvertiserRegister($input: AdvertiserRegisterInput) {
    auth {
      advertiserRegister(input: $input) {
        token
        expires
        issued
        isVerified
        group
        id
      }
    }
  }
`;

export const ADVERTISER_LOGIN = gql`
  mutation Login($input: LoginInput) {
    auth {
      login(input: $input) {
        token
        isVerified
        group
        id
      }
    }
  }
`;

export const ADD_PROGRAMME_SCHEDULE = gql`
  mutation AddProgramSchedule($input: AddProgramScheduleInput) {
    broadcaster {
      addProgramSchedule(input: $input) {
        weekDay
        status
        startTime
        sponsor
        show
        id
        endTime
      }
    }
  }
`;

export const UPDATE_PROGRAMME_SCHEDULE = gql`
  mutation UpdateProgramSchedule($input: UpdateProgramScheduleInput) {
    broadcaster {
      updateProgramSchedule(input: $input) {
        id
        startTime
        endTime
        show
        sponsor
        status
        weekDay
      }
    }
  }
`;

export const DELETE_PROGRAMME_SCHEDULE = gql`
  mutation DeleteProgramSchedule($deleteProgramScheduleId: ID!) {
    broadcaster {
      deleteProgramSchedule(id: $deleteProgramScheduleId)
    }
  }
`;

export const BROADCASTER_REGISTER = gql`
  mutation BroadcasterRegister($input: BroadcasterRegisterInput) {
    auth {
      broadcasterRegister(input: $input) {
        id
        token
        expires
        issued
        isVerified
        group
      }
    }
  }
`;

export const OTP_CONFIRMATION = gql`
  mutation VerifyEmail($verificationCode: String!) {
    auth {
      verifyEmail(verificationCode: $verificationCode) {
        token
        expires
        issued
        isVerified
        group
      }
    }
  }
`;
export const CREATE_CAMPAIGN = gql`
  mutation CreateCampaign($input: createCampaignInput) {
    advertiser {
      createCampaign(input: $input) {
        ... on PayFromWallet {
          paymentMethod
          result
        }
        ... on PayFromPaystack {
          paymentMethod
          authorizationUrl
          transactionId
        }
        ... on PayFromEnd2End {
          paymentMethod
          apiKey
          accessKey
          amount
          currency
          userEmail
          callbackUrl
          referenceId
        }
      }
    }
  }
`;

export const DASHBOARD_DATA = gql`
  query GetDashboardData {
    advertiser {
      getDashboardData {
        activeCampaign
        pendingCampaign
        totalCampaign
        recentCampaigns {
          campaignStatus
          campaignDetails
          id
        }
      }
    }
  }
`;

// check later
export const BOOK_SLOTS = gql`
  mutation BookSlots($input: [bookSlotsInput]) {
    advertiser {
      bookSlots(input: $input)
    }
  }
`;

export const UPDATE_BROADCASTER_SLOT = gql`
  mutation BatchUpdateSlot($input: [UpdateSlotInput]) {
    broadcaster {
      batchUpdateSlot(input: $input) {
        updateCount
        message
      }
    }
  }
`;

export const CREATE_DRAFTS = gql`
  mutation CreateDraft($input: [createDraftInput]) {
    advertiser {
      createDraft(input: $input) {
        id
        summary {
          vat
          subTotal
          totalDiscountApplied
          grandTotal
          campaignSummary {
            id
            name
            price
            banner
          }
        }
      }
    }
  }
`;
export const UPDATE_PASSWORD = gql`
  mutation UpdatePassword($input: updatePasswordInput) {
    advertiser {
      updatePassword(input: $input)
    }
  }
`;

export const SEND_EXPERT_EMAIL = gql`
  mutation SendExpertEmail($input: sendExpertEmailInput) {
    guest {
      sendExpertEmail(input: $input)
    }
  }
`;
export const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($input: ForgotPasswordInput) {
    auth {
      forgotPassword(input: $input) {
        message
      }
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPassword($input: ResetPasswordInput) {
    auth {
      resetPassword(input: $input) {
        message
      }
    }
  }
`;

export const PAY_FOR_CAMPAIGN_FROM_PAYSTACK = gql`
  mutation PayForCampaignFromPaystack($input: payForCampaignFromPaystackInput) {
    advertiser {
      payForCampaignFromPaystack(input: $input) {
        status
        message
      }
    }
  }
`;

export const REFRESH_TRANSACTION = gql`
  mutation RefreshTransaction($transactionId: ID!) {
    advertiser {
      refreshTransaction(transactionId: $transactionId) {
        status
        message
      }
    }
  }
`;

export const CREATE_PORTFOLIO = gql`
  mutation CreatePortfolio($input: createPortfolioInput) {
    advertiser {
      createPortfolio(input: $input)
    }
  }
`;

export const ENABLE_ANALYTICS = gql`
  mutation EnableAnalytics($camapignId: ID!, $protfolioId: ID!) {
    advertiser {
      enableAnalytics(camapignId: $camapignId, protfolioId: $protfolioId)
    }
  }
`;

export const VERIFY_BROADCASTER_EMAIL = gql`
  mutation VerifyBroadcasterEmail($verifyBroadcasterEmailId: ID!) {
    admin {
      verifyBroadcasterEmail(id: $verifyBroadcasterEmailId)
    }
  }
`;

export const APPROVE_RADIOSTATION = gql`
  mutation ApproveBroadcaster($approveBroadcasterId: ID!) {
    admin {
      approveBroadcaster(id: $approveBroadcasterId)
    }
  }
`;

export const DELETE_PORTFOLIO = gql`
  mutation DeletePortfolio($portfolioId: ID!) {
    advertiser {
      deletePortfolio(portfolioId: $portfolioId)
    }
  }
`;

export const FUND_WALLET = gql`
  mutation InitializeFundWallet($amount: Int!, $confirmPageUrl: String!) {
    advertiser {
      initializeFundWallet(amount: $amount, confirmPageUrl: $confirmPageUrl) {
        paymentMethod
        authorizationUrl
        transactionId
      }
    }
  }
`;

export const ADMIN_UPDATE_TRANSACTION_STATUS = gql`
  mutation UpdateTransactionStatus(
    $transactionId: ID!
    $status: TransactionStatus!
  ) {
    admin {
      updateTransactionStatus(transactionId: $transactionId, status: $status)
    }
  }
`;
export const VIEWED_NOTIFICATION = gql`
  mutation Notification($notificationId: [Int]!) {
    notification {
      markViewedNotification(notificationId: $notificationId)
    }
  }
`;
export const ADMIN_UPDATE_ACCOUNT_STATUS = gql`
  mutation UpdateAccountStatus($userId: ID!, $status: Boolean!) {
    admin {
      updateAccountStatus(userId: $userId, status: $status)
    }
  }
`;
export const ADMIN_UPDATE_SPECIAL_ADVISER_DISCOUNT = gql`
  mutation UpdateSpecialAdvertiserDiscount($userId: String, $discount: Int) {
    admin {
      updateSpecialAdvertiserDiscount(userId: $userId, discount: $discount)
    }
  }
`;

export const UPDATE_BROADCASTER_PROFILEDETAILS_BY_ADMIN = gql`
  mutation UpdateBroadcasterProfileDetails(
    $input: updateBroadcasterProfileDetailsInput
  ) {
    admin {
      updateBroadcasterProfileDetails(input: $input) {
        radioStation
        nameOfPersonInCharge
        position
        contactNo
        address
        country
        state
        city
        zipcode
        radioCategory
        website
        digitalStreamUrl
      }
    }
  }
`;

export const UPDATE_BROADCASTER_PASSWORD_BY_ADMIN = gql`
  mutation UpdateBroadcastePassword($input: updateBroadcastePassword) {
    admin {
      updateBroadcastePassword(input: $input)
    }
  }
`;

export const UPDATE_BROADCASTER_BANK_ACCOUNT_BY_ADMIN = gql`
  mutation SaveBroadcasterBankAccount(
    $input: saveBroadcasterBankAccountInputForAdmin
  ) {
    admin {
      saveBroadcasterBankAccount(input: $input) {
        bankCode
        accountNumber
        accountName
        bankName
      }
    }
  }
`;
export const BATCH_UPDATE_BROADCASTER_SLOT_BY_ADMIN = gql`
  mutation BatchUpdateSlot($input: [UpdateSlotInput]) {
    admin {
      batchUpdateSlot(input: $input) {
        message
        updateCount
      }
    }
  }
`;

export const CREATE_BROADCASTER_DISCOUNT_BY_ADMIN = gql`
  mutation CreateBroadcasterVolumeDiscount(
    $input: createBroadcasterVolumeDiscountInput
  ) {
    admin {
      createBroadcasterVolumeDiscount(input: $input) {
        id
        userId
        discount
        fromAmount
        toAmount
      }
    }
  }
`;

export const CREATE_BROADCASTER_PROGRAMSCHEDULE_BY_ADMIN = gql`
  mutation CreateBroadcasterProgramSchedule(
    $input: createBroadcasterProgramScheduleInput
  ) {
    admin {
      createBroadcasterProgramSchedule(input: $input) {
        id
        startTime
        endTime
        show
        sponsor
        status
        weekDay
        broadcasterId
      }
    }
  }
`;
export const UPDATE_BROADCASTER_PROGRAMSCHEDULE_BY_ADMIN = gql`
  mutation UpdateProgramSchedule($input: UpdateProgramScheduleInput) {
    admin {
      updateProgramSchedule(input: $input) {
        id
        startTime
        endTime
        show
        sponsor
        status
        weekDay
        broadcasterId
      }
    }
  }
`;
export const DELETE_BROADCASTER_PROGRAMSCHEDULE_BY_ADMIN = gql`
  mutation DeleteProgramSchedule($deleteProgramScheduleId: ID!) {
    admin {
      deleteProgramSchedule(id: $deleteProgramScheduleId)
    }
  }
`;

export const UPDATE_ADVERTISER_DETAILS = gql`
  mutation UpdateAdvertiserDetails($input: updateAdvertiserDetailsInput) {
    admin {
      updateAdvertiserDetails(input: $input) {
        id
        name
        industry
        address
        phone
        country
        state
        city
        accountType
        referralCode
        accountStatus
        specialAdvertiserDiscount
      }
    }
  }
`;

export const UPDATE_ADMIN_CAMPAIGN = gql`
  mutation UpdateCampaign($input: updateCampaignInput) {
    admin {
      updateCampaign(input: $input)
    }
  }
`;

export const DELETE_ADMIN_CAMPAIGN = gql`
  mutation DeleteCampaign($deleteCampaignId: ID!) {
    admin {
      deleteCampaign(id: $deleteCampaignId)
    }
  }
`;

export const EXPORT_ADMIN_CAMPAIGN = gql`
  mutation ExportCampaign($campaignId: ID!) {
    broadcaster {
      exportCampaign(campaignId: $campaignId)
    }
  }
`;
