import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  GET_NOTIFICATIONS,
  USER_INFO,
} from "../../../components/GraphQL/Queries";
import Logo from "../../../components/Logo";
import Hamburger from "../assets/hamburger.svg";
import Notification from "../assets/notification.svg";
import UserIcon from "../assets/user.svg";
import PersonIcon from "../assets/person.svg";
import transmitLogo from "../../../transmitAssets/logo-and-text.svg";
import BalanceIcon from "../assets/balance.svg";
import PlusIcon from "../assets/plus.svg";
import { useQuery, useSubscription } from "@apollo/client";
import { ChakraProvider, useDisclosure } from "@chakra-ui/react";
import { extendTheme } from "@chakra-ui/react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  HStack,
  VStack,
  Skeleton,
} from "@chakra-ui/react";

import { Container, PopoverText, ProfileIcon, LogOutIcon } from "./components";
import "./style.css";
import { handleLogOut } from "./utils";
import { CloseIcon } from "@chakra-ui/icons";

import { menuList } from "../../../utils/constant";
import Logout from "../../../components/Modal/Logout/Logout";
import NotificationsPopOver from "../../../components/Notifications/NotificationsPopOver";

const breakpoints = {
  sm: "320px",
  md: "768px",
  lg: "960px",
  xl: "1200px",
  "2xl": "1536px",
};

const theme = extendTheme({ breakpoints });

const AdvertDashboardLayout = ({ children }) => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isNotificationModal,
    onOpen: openNotificationModal,
    onClose: closeNotificationModal,
  } = useDisclosure();

  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);

  useEffect(() => {
    //Check for the store state and update the state.
    const storedIndex = localStorage.getItem("selectedItemIndex");
    setSelectedItemIndex(storedIndex !== null ? Number(storedIndex) : 0);
    if (selectedItemIndex && menuList.length >= 0) {
      navigate(menuList[selectedItemIndex].link);
    }
  }, []);

  const handleMenuItemClick = (index, itemId) => {
    setSelectedItemIndex(index);
    localStorage.setItem("selectedItemIndex", index);
  };

  const { data, loading } = useQuery(USER_INFO);
  const { data: notificationData, loading: notificationLoading } =
    useSubscription(GET_NOTIFICATIONS);

  return (
    <ChakraProvider theme={theme}>
      {isOpen && <Logout isOpen={isOpen} onClose={onClose} />}
      <div className="advert-dashboard-layout">
        <nav>
          <div className="advert-dashboard-nav-container">
            <div className="nav-wrapper">
              <div>
                <Link to="/">
                  <img
                    src={transmitLogo}
                    alt="transmitLogo"
                    width="70%"
                    className="image-logo"
                    // height="100%"
                  />
                </Link>
              </div>
              <div onClick={() => setOpenMobileMenu(!openMobileMenu)}>
                <img
                  src={Hamburger}
                  alt="hamburger_icon"
                  className="hamburger"
                />
              </div>
            </div>

            <div className="advertiser-name">
              {loading ? (
                <Skeleton width="80px" height="20px" />
              ) : (
                data?.auth.me.fullname
              )}
            </div>
          </div>
          {openMobileMenu && (
            <div className="mobile-show-nav-menu-container">
              <div className="mobile-sidebar-wrapper">
                <div>
                  <Link to="/">
                    <img
                      src={transmitLogo}
                      alt="transmitLogo"
                      width="70%"
                      // height="50%"
                    />
                  </Link>
                </div>
                <div className="mobile-icon-wrapper">
                  <div
                    onClick={openNotificationModal}
                    style={{ cursor: "pointer", position: "relative" }}
                  >
                    <img src={Notification} alt="notification" />

                    {isNotificationModal && (
                      <NotificationsPopOver
                        isNotificationModal={isNotificationModal}
                        openNotificationModal={openNotificationModal}
                        closeNotificationModal={closeNotificationModal}
                        data={notificationData}
                      />
                    )}
                  </div>
                  <div onClick={() => setOpenMobileMenu(!openMobileMenu)}>
                    <CloseIcon  color="#f1613f" />
                  </div>

                  {/* <img src={UserIcon} alt="user_icon" /> */}
                </div>
              </div>
              <div className="mobile-sidebar-menu">
                {menuList.map(({ text, Icon, link }, index) => {
                  return (
                    <Link
                      to={link}
                      onClick={() => handleMenuItemClick(index, index.id)}
                    >
                      <div
                        className={`mobile-sidebat-item ${
                          selectedItemIndex === index ? "selected-mobile" : ""
                        }`}
                      >
                        <Icon />
                        <span>{text}</span>
                      </div>
                    </Link>
                  );
                })}
                <div onClick={() => handleLogOut(navigate)}>
                  <div className="mobile-sidebat-item">
                    <LogOutIcon />
                    <span className="logout-text">Logout</span>
                  </div>
                </div>
              </div>
              <Link to="/book-campaign">
                <button className="dashboard-campaign-btn">
                  <img src={PlusIcon} alt="" />
                  <span>new campaign</span>
                </button>
              </Link>
            </div>
          )}
        </nav>
        {children}
      </div>

      <div className="desktop-dashboard-layout">
        <div className="dashboard-sidebar-container">
          <div>
            <Link to="/">
              <img
                src={transmitLogo}
                alt="transmitLogo"
                width="70%"
                className="image-logo"
                // height="100%"
              />
            </Link>
          </div>

          <div className="dashboard-sidebar-menu-items">
            {menuList.map(({ text, Icon, link }, index) => {
              return (
                <Link
                  to={link}
                  onClick={() => handleMenuItemClick(index, index.id)}
                >
                  <div
                    className={`sidebar-menu-item ${
                      selectedItemIndex === index ? "selected" : ""
                    }`}
                  >
                    <Icon />
                    <span>{text}</span>
                  </div>
                </Link>
              );
            })}
          </div>
          <Link to="/book-campaign">
            <button className="dashboard-campaign-btn">
              {onOpen}
              <img src={PlusIcon} alt="" />
              <span>new campaign</span>
            </button>
          </Link>
        </div>
        <div className="dashboard-nav-body-container">
          <nav>
            <div>
              {loading ? (
                <Skeleton width="80px" height="20px" />
              ) : (
                data?.auth.me.fullname
              )}
            </div>
            <div className="nav-icon-wrapper">
              <Link to="/dashboard/wallet">
                <img src={BalanceIcon} alt="balance_icon" className="dasboard-nav-icon" style={{ fill: '#f1613f' }} />
              </Link>
              <div
                onClick={openNotificationModal}
                style={{ cursor: "pointer", position: "relative" }}
              >
                <img src={Notification} alt="notification" />

                {isNotificationModal && (
                  <NotificationsPopOver
                    isNotificationModal={isNotificationModal}
                    openNotificationModal={openNotificationModal}
                    closeNotificationModal={closeNotificationModal}
                    data={notificationData}
                  />
                )}
              </div>

              <Popover placement="bottom-start">
                <PopoverTrigger>
                  <div style={{ cursor: "pointer" }}>
                    <img src={UserIcon} alt="user-icon" />
                  </div>
                </PopoverTrigger>

                <PopoverContent
                  w="158px"
                  h="89px"
                  bg="#ffffff"
                  border="0.5px solid #aba8a8"
                  borderRadius="2px"
                  p="15px 0px"
                >
                  <VStack>
                    <Container onClick={() => navigate("/dashboard/profile")}>
                      <HStack>
                        <ProfileIcon />
                        <PopoverText>Profile</PopoverText>
                      </HStack>
                    </Container>
                    <Container onClick={onOpen}>
                      <HStack>
                        <LogOutIcon />
                        <PopoverText>Logout</PopoverText>
                      </HStack>
                    </Container>
                  </VStack>
                </PopoverContent>
              </Popover>
            </div>
          </nav>
          {children}
        </div>
      </div>
    </ChakraProvider>
  );
};

export default AdvertDashboardLayout;
