import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./css/station-details.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./css/station-details.css";
import { css } from "@emotion/react";
import moment from "moment";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Input,
  useQuery,
  Box,
} from "@chakra-ui/react";
import styled from "styled-components";
import Svg from "./Svg";
import { BROADCASTER_SLOTS_GQL } from "../../components/GraphQL/Queries";
import EmptyStateGif from "../../assets/gifs/empty-state.gif";
import { Switch } from "@chakra-ui/react";
import {
  MdControlPoint,
  MdOutlineCreate,
  MdOutlineDelete,
} from "react-icons/md";
import { FaEye } from "react-icons/fa6";
import { Popover, PopoverTrigger, PopoverContent } from "@chakra-ui/react";
import IncrementDecrement from "./IncrementDecrement";

const StationDetails = ({
  title,
  tagline,
  isOpen,
  setMutatedBroadcasterData,
  mutatedbroadcasterData,
  broadcasterData,
  setLanguage,
  broadcasterId,
  accountType,
  totalPrice,
}) => {
  const {
    isOpen: isOpenChakra,
    onOpen: openDatePicker,
    onClose,
  } = useDisclosure();
  const [slotId] = useState(null);
  const [slotQuantity] = useState(0);
  const [advertTypeSelected, setAdvertTypeSelected] = useState(null);
  const [checkedSelectedSlot, setCheckedSelectedSlot] = useState([]);
  const [openSlotModal, setOpenSlotModal] = useState(false);
  const [openPrimaryModal, setOpenPrimaryModal] = useState(false);
  const [tabs, setTabs] = useState(0);
  // const [priceTag, setPriceTag] = useState(null);

  // const [slotNumber, setSlotNumber] = useState({});
  // console.log("pricetag", data);

  const [isChecked, setIsChecked] = useState(false);
  const [
    duplicateSelectedSlotsAcrossDatesIfDateChange,
    setDuplicateSelectedSlotsAcrossDatesIfDateChange,
  ] = useState(false);

  const [bookingInfo, setBookingInfo] = useState({
    advertType: "",
    duration: "",
    language: "",
    slotId: slotId,
    slotQuantity: slotQuantity,
  });

  const handleChange = (e) => {
    setLanguage(e.target.value);
    const newbroadcasterData = mutatedbroadcasterData.map((broadcasterData) => {
      broadcasterData = { ...broadcasterData };
      if (broadcasterData["guest"].name === title) {
        broadcasterData["guest"]["language"] = e.target.value;
      }
      return broadcasterData;
    });
    setMutatedBroadcasterData(newbroadcasterData);
    setBookingInfo({
      ...bookingInfo,
      [e.target.name]: e.target.value,
    });
  };

  const startDatePicker = useRef();
  const startDateView = useRef();
  const endDatePicker = useRef();
  const endDateView = useRef();
  const initialFocusRef = useRef();

  const currentDateValue = new Date();
  const dateOffset = accountType === "Agency" ? 0 : 1;

  // Create a new date object with the offset
  const adjustedDate = new Date(currentDateValue);
  adjustedDate.setDate(currentDateValue.getDate() + dateOffset);

  const currDateFormatted = adjustedDate.toISOString().split("T")[0];
  // console.log(bookingInfo);

  const handleAdvertType = (e) => {
    const { value } = e.target;
    setBookingInfo({ ...bookingInfo, advertType: value });
    const newbroadcasterData = mutatedbroadcasterData.map((broadcasterData) => {
      const currDate = moment(new Date()).add(1, "day").format("YYYY-MM-DD");
      broadcasterData = { ...broadcasterData };
      if (broadcasterData.guest.name === title) {
        broadcasterData["guest"]["advertType"] = value;
        broadcasterData["guest"]["startDate"] = new Date(currDateFormatted);
        broadcasterData["guest"]["endDate"] = new Date(currDateFormatted);
        broadcasterData["guest"]["dateRange"] = {};
        broadcasterData["guest"]["dateRange"][`${currDate}`] = {
          slotData: [
            ...broadcasterData?.guest?.getBroadcasterSlots.map((slot) => {
              slot = { ...slot };
              slot["date"] = currDate;
              slot["language"] = "";
              return slot;
            }),
          ],
          totalSlot: 0,
          totalPrice: 0,
        };

        broadcasterData["guest"]["totalDays"] = 1;
        broadcasterData["guest"]["totalSpots"] = 0;
        broadcasterData["guest"]["totalPrice"] = 0;
      }
      return broadcasterData;
    });
    setMutatedBroadcasterData(newbroadcasterData);
  };
  const handleAdvertDur = (e) => {
    const { value } = e.target;
    setAdvertTypeSelected(value);
    let currDate

    if (accountType === "Agency") {
      currDate = moment(new Date()).format("YYYY-MM-DD");
    } else {
      currDate = moment(new Date())
        .add(1, "day")
        .format("YYYY-MM-DD");
    }
    const newbroadcasterData = mutatedbroadcasterData.map((broadcasterData) => {
      broadcasterData = { ...broadcasterData };
      if (broadcasterData.guest.name === title) {
        broadcasterData["guest"]["duration"] = value;
        broadcasterData["guest"]["startDate"] = new Date(currDateFormatted);
        broadcasterData["guest"]["endDate"] = new Date(currDateFormatted);
        broadcasterData["guest"]["dateRange"] = {};
        broadcasterData["guest"]["dateRange"][`${currDate}`] = {
          slotData: [
            ...broadcasterData?.guest?.getBroadcasterSlots.map((slot) => {
              slot = { ...slot };
              slot["date"] = currDate;
              return slot;
            }),
          ],
          totalSlot: 0,
          totalPrice: 0,
        };

        broadcasterData["guest"]["totalDays"] = 1;
        broadcasterData["guest"]["totalSpots"] = 0;
        broadcasterData["guest"]["totalPrice"] = 0;
      }
      return broadcasterData;
    });
    setMutatedBroadcasterData([...newbroadcasterData]);
    setBookingInfo({ ...bookingInfo, duration: value });
  };

  function getDates(startDate, stopDate) {
    let dateArray = [];
    let currentDate = moment(startDate);
    stopDate = moment(stopDate);
    while (currentDate < stopDate) {
      dateArray.push(moment(currentDate).format("YYYY-MM-DD"));
      currentDate = moment(currentDate).add(1, "days");
    }

    const formattedStopDate = moment(stopDate).format("YYYY-MM-DD");

    // const containsStopDate = dateArray.some(
    //   (date) => date === formattedStopDate
    // );
    // if (containsStopDate) {
    //   return dateArray;
    // }

    dateArray = [...dateArray, formattedStopDate];
    return dateArray;
  }

  const [slotDataList, setSlotDataList] = useState([]);

  const getAdvertType = () => {
    if (broadcasterData.advertType === "Jingle") {
      if (broadcasterData.duration === "15Sec") {
        return "Jingle15Sec";
      }
      if (broadcasterData.duration === "30Sec") {
        return "Jingle30Sec";
      }
      if (broadcasterData.duration === "45Sec") {
        return "Jingle45Sec";
      }
      if (broadcasterData.duration === "60Sec") {
        return "Jingle60Sec";
      }
    }
    if (broadcasterData.advertType === "paidAdvertisements") {
      if (broadcasterData.duration === "50Words") {
        return "Ann50Words";
      }
      if (broadcasterData.duration === "75Words") {
        return "Ann75Words";
      }
      if (broadcasterData.duration === "100Words") {
        return "Ann100Words";
      }
    }
    return "";
  };

  const onChangeDateHandler = (e, date) => {
    const currentDate = new Date(e.target.value);
    const startDate = new Date(broadcasterData?.startDate);
    const endDate = new Date(broadcasterData?.endDate);

    let currentBroadcasterData = { ...broadcasterData?.dateRange };
    let dateRangeList = [];

    if (date === "start") {
      dateRangeList = getDates(currentDate, endDate);
    } else if (date === "end") {
      dateRangeList = getDates(startDate, currentDate);
    }

    const updatedBroadcasterData = Object.fromEntries(
      Object.entries(currentBroadcasterData)
        .filter(([date]) => dateRangeList.includes(date))
        .map(([date, data]) => [date, data])
    );

    currentBroadcasterData = updatedBroadcasterData;

    setDuplicateSelectedSlotsAcrossDatesIfDateChange(
      !duplicateSelectedSlotsAcrossDatesIfDateChange
    );

    dateRangeList.forEach((date) => {
      if (!currentBroadcasterData[date]) {
        currentBroadcasterData[date] = {
          slotData: broadcasterData?.getBroadcasterSlots.map((slot) => ({
            ...slot,
            date,
            advertType: getAdvertType(),
            slotNumber: 0,
            language: broadcasterData?.language,
          })),
          totalSlot: 0,
          totalPrice: 0,
          date,
        };
      }
    });

    const newbroadcasterData = mutatedbroadcasterData.map((data) => {
      if (data.guest.name === title) {
        data.guest.totalDays = dateRangeList.length;
        if (date === "start") {
          data.guest.startDate = currentDate;
        } else if (date === "end") {
          data.guest.endDate = currentDate;
        }
        data.guest.dateRange = { ...currentBroadcasterData };
      }
      return { ...data };
    });

    setMutatedBroadcasterData(newbroadcasterData);
  };

  const [currentDate, setCurrentDate] = useState("");

  const currentDateList =
    broadcasterData?.dateRange[`${moment(currentDate).format("YYYY-MM-DD")}`]
      ?.slotData;

  const activeStation = mutatedbroadcasterData.find(
    (radioStation) => radioStation.guest.id === broadcasterId
  );
  const activeBroadcasterStation = mutatedbroadcasterData.filter(
    (radioStation) => radioStation.guest.id === broadcasterId
  );

  const slotInputChangeHandler = (e, { id }) => {
    console.log(e, "casted");
    // Convert input value to a number
    const castedNumberVal = Number(e);
    const numberCheck = isNaN(castedNumberVal);

    // Mutate slotDataList based on input changes
    const mutatedDataList = slotDataList?.slotData?.map((slot) => {
      // Clone the slot object to avoid mutating original data
      slot = { ...slot };

      // Check if the current slot matches the input id
      if (slot?.id === id) {
        slot["slotNumber"] = !numberCheck ? castedNumberVal : slot?.slotNumber;
        const currNum = !numberCheck ? castedNumberVal : slot?.slotNumber;

        // setSlotNumber((prevInputData) => ({
        //   ...prevInputData,
        //   [id]: castedNumberVal,
        // }));

        if (broadcasterData.advertType === "Jingle") {
          if (broadcasterData.duration === "15Sec") {
            slot["cost"] = slot["Jingle15SecPrice"] * currNum;
          }
          if (broadcasterData.duration === "30Sec") {
            slot["cost"] = slot["Jingle30SecPrice"] * currNum;
          }
          if (broadcasterData.duration === "45Sec") {
            slot["cost"] = slot["Jingle45SecPrice"] * currNum;
          }
          if (broadcasterData.duration === "60Sec") {
            slot["cost"] = slot["Jingle60SecPrice"] * currNum;
          }
        }
        if (broadcasterData.advertType === "paidAdvertisements") {
          if (broadcasterData.duration === "50Words") {
            slot["cost"] = slot["Ann50WordsPrice"] * Number(e);
          }
          if (broadcasterData.duration === "75Words") {
            slot["cost"] = slot["Ann75WordsPrice"] * Number(e);
          }
          if (broadcasterData.duration === "100Words") {
            slot["cost"] = slot["Ann100WordsPrice"] * Number(e);
          }
        }
      }

      return slot;
    });

    const current = mutatedDataList.find((slot) => slot.id === id);
    let currTotalSlot = !numberCheck ? castedNumberVal : current?.slotNumber;
    const currTotalSlotList =
      currentDateList && currentDateList.filter((slot) => slot?.id !== id);

    for (let index = 0; index < currTotalSlotList.length; index++) {
      const element = currTotalSlotList[index];
      currTotalSlot += element?.slotNumber ?? 0;
    }

    let currTotalPrice = 0;

    for (let index = 0; index < mutatedDataList.length; index++) {
      const element = mutatedDataList[index];
      currTotalPrice += element?.cost ?? 0;
    }

    let newData = { ...broadcasterData };
    newData["dateRange"][`${moment(currentDate).format("YYYY-MM-DD")}`][
      "slotData"
    ] = [...mutatedDataList];
    newData["dateRange"][`${moment(currentDate).format("YYYY-MM-DD")}`][
      "totalSlot"
    ] = currTotalSlot;
    newData["dateRange"][`${moment(currentDate).format("YYYY-MM-DD")}`][
      "totalPrice"
    ] = currTotalPrice;

    const newBroadcaster = mutatedbroadcasterData.map((broadcaster) => {
      broadcaster = { ...broadcaster };
      if (broadcaster.name === title) {
        broadcaster.dateRange = { ...newData?.dateRange };
      }
      return broadcaster;
    });

    setMutatedBroadcasterData(newBroadcaster);

    setSlotDataList((prevState) => ({
      ...prevState,
      slotData: mutatedDataList,
    }));

    // Handle additional logic when isChecked is true
    if (activeStation.guest.isChecked) {
      const selectedRadioStationInfo = mutatedbroadcasterData.filter(
        (radioStation) => radioStation.guest.id !== broadcasterId
      );
      const getRadioStationWithSelectedSlot = mutatedbroadcasterData.filter(
        (radioStation) => radioStation.guest.id === broadcasterId
      );
      const newData = [...getRadioStationWithSelectedSlot];

      // Calculate costsForCurrentSlot for the current input id
      const costsForCurrentSlot = newData.map(({ guest }) => {
        const { slotData } = guest.dateRange[currentDate];
        const slotWithInputId = slotData.find((s) => s.id === id);
        return slotWithInputId.cost;
      });

      newData.forEach(({ guest }) => {
        const dateKeys = Object.keys(guest.dateRange);

        dateKeys.forEach((dateKey) => {
          // Update slotData, totalSlot, and totalPrice for each dateKey
          const slotData = guest.dateRange[dateKey].slotData;
          const updatedSlotData = slotData.map((slot) => {
            if (slot.id === id) {
              const updatedSlot = {
                ...slot,
                slotNumber: castedNumberVal,
                cost: costsForCurrentSlot[0],
              };
              return updatedSlot;
            }
            return slot;
          });
          guest.dateRange[dateKey].slotData = updatedSlotData;

          const totalSlot = updatedSlotData
            .map((slotInfo) => slotInfo.slotNumber)
            .filter((slotNum) => slotNum > 0)
            .reduce((total, slot) => total + slot, 0);

          const totalPrice = updatedSlotData
            .filter((slotInfo) => slotInfo.hasOwnProperty("cost"))
            .map(({ cost }) => cost)
            .reduce((total, cost) => total + cost, 0);

          guest.dateRange[dateKey].totalSlot = totalSlot;
          guest.dateRange[dateKey].totalPrice = totalPrice;
        });

        // Calculate totalSpots and totalPrice for each guest
        const getTotalSpots = dateKeys.map(
          (date) => guest.dateRange[date].totalSlot
        );
        const getTotalSpotsPrice = dateKeys.map(
          (date) => guest.dateRange[date].totalPrice
        );

        guest.totalSpots = getTotalSpots.reduce(
          (initialNoSpot, spotValue) => initialNoSpot + spotValue,
          0
        );
        guest.totalPrice = getTotalSpotsPrice.reduce(
          (initialAmount, spotAmount) => initialAmount + spotAmount,
          0
        );
      });

      // Update mutatedBroadcasterData with new data
      setMutatedBroadcasterData([newData[0], ...selectedRadioStationInfo]);
    }
  };

  const [allSlotsArrayData, setAllSlotArrayData] = useState(false);

  useEffect(() => {
    if (allSlotsArrayData) {
      const allSlots = Object.values(broadcasterData.dateRange).reduce(
        (acc, curr) => acc + curr.totalSlot,
        0
      );
      const allCosts = Object.values(broadcasterData.dateRange).reduce(
        (acc, curr) => acc + curr.totalPrice,
        0
      );

      const allSlotsData = Object.values(broadcasterData.dateRange)
        .map((slot) => {
          return [...slot.slotData];
        })
        .flat()
        .filter((slotData) => !!slotData.slotNumber)
        .map((slot) => {
          slot = { ...slot };
          slot["advertType"] = getAdvertType();
          slot["language"] = broadcasterData?.language;

          return slot;
        });

      const newbroadcasterData = mutatedbroadcasterData.map(
        (broadcasterData) => {
          broadcasterData = { ...broadcasterData };
          if (broadcasterData["guest"].name === title) {
            broadcasterData["guest"].totalSpots = allSlots;
            broadcasterData["guest"].totalPrice = allCosts;
            broadcasterData["guest"].allSlots = [...allSlotsData];
          }
          return broadcasterData;
        }
      );

      setMutatedBroadcasterData(newbroadcasterData);
    }
  }, [allSlotsArrayData]);

  const saveHandler = () => {
    if (duplicateAcrossMultipleRadioStation) {
      fn(mutatedbroadcasterData, activeBroadcasterStation[0]);
    }
    setAllSlotArrayData(Math.floor(Math.random() * 1000) + 1);
    ModalClose()
    // onClose();
  };

  const currentNumberofDays =
    broadcasterData && Object.values(broadcasterData?.dateRange).length;


  const duplicateSelectedSlotsAcrossDates = () => {
    const checkStatus = !isChecked;
    setIsChecked(checkStatus);
    broadcasterData.isChecked = checkStatus;
  };


  useEffect(() => {
    const updateRadioStationData = () => {

      const selectedRadioStationInfo = mutatedbroadcasterData.filter(
        (radioStation) => radioStation.guest.id !== broadcasterId
      );

      const getRadioStationWithSelectedSlot = mutatedbroadcasterData.filter(
        (radioStation) => radioStation.guest.id === broadcasterId
      );

      const updatedRadioStationWithSelectedIsChecked =
        getRadioStationWithSelectedSlot.map(({ guest }) => ({
          guest: {
            ...guest,
          },
        }))

      if (broadcasterData.isChecked) {
        const newData = [...updatedRadioStationWithSelectedIsChecked];
        const slotDataAcrossDates = newData
          .map(({ guest }) => Object.values(guest.dateRange))
          .flatMap((item) => item)
          .map(({ slotData }) => slotData)
          .flatMap((slotDataObj) => slotDataObj)
          .filter((a) => a.slotNumber > 0);

        newData.forEach(({ guest }) => {
          const dateKeys = Object.keys(guest.dateRange);

          dateKeys.forEach((dateKey) => {
            const slotData = guest.dateRange[dateKey].slotData;

            // Duplicate the first slotData object for other objects with the same ID
            for (let i = 0; i < slotData.length; i++) {
              const matchingSlot = slotDataAcrossDates.find(
                (existSlot) => slotData[i].id === existSlot.id
              );
              if (matchingSlot) {
                slotData[i] = { ...matchingSlot, date: dateKey };
              }
            }
            const totalSlot = slotData
              .map((slotInfo) => slotInfo.slotNumber)
              .filter((slotNum) => slotNum > 0)
              .reduce((total, slot) => total + slot, 0);

            const totalPrice = slotData
              .map((slotInfo) => slotInfo)
              .filter((slotNum) => slotNum.hasOwnProperty("cost"))
              .map(({ cost }) => cost)
              .reduce((total, slot) => total + slot, 0);

            guest.dateRange[dateKey].totalSlot = totalSlot;
            guest.dateRange[dateKey].totalPrice = totalPrice;
          });
          const getTotalSpots = dateKeys.map(
            (date) => guest.dateRange[date].totalSlot
          );
          const getTotalSpotsPrice = dateKeys.map(
            (date) => guest.dateRange[date].totalPrice
          );
          guest.totalSpots = getTotalSpots.reduce(
            (initialNoSpot, spotValue) => initialNoSpot + spotValue
          );
          guest.totalPrice = getTotalSpotsPrice.reduce(
            (initialAmount, spotAmount) => initialAmount + spotAmount
          );
        });
        return setMutatedBroadcasterData([
          ...selectedRadioStationInfo,
          ...newData,
        ]);
      } else {
        const newData = [...updatedRadioStationWithSelectedIsChecked];

        newData.forEach(({ guest }) => {
          const dateKeys = Object.keys(guest.dateRange);

          dateKeys.forEach((dateKey) => {
            const slotData = guest.dateRange[dateKey].slotData;

            // Remove duplicate slots based on ID
            const uniqueSlots = slotData.filter(
              (slot, index, self) =>
                self.findIndex((s) => s.id === slot.id) === index
            );

            // Remove duplicate slotNumbers across different dates
            const uniqueSlotNumbers = Array.from(
              new Set(uniqueSlots.map((slot) => slot.slotNumber))
            );

            // Update slotNumbers for other dates
            dateKeys.forEach((otherDateKey) => {
              if (otherDateKey !== dateKey) {
                const otherDateSlots = guest.dateRange[otherDateKey].slotData;
                const updatedOtherDateSlots = otherDateSlots.map((slot) => {
                  if (uniqueSlotNumbers.includes(slot.slotNumber)) {
                    return { ...slot, slotNumber: 0 };
                  }
                  return slot;
                });
                guest.dateRange[otherDateKey].slotData = updatedOtherDateSlots;
              }
            });

            guest.dateRange[dateKey].slotData = uniqueSlots;

            const totalSlot = uniqueSlots
              .map((slotInfo) => slotInfo.slotNumber)
              .filter((slotNum) => slotNum > 0)
              .reduce((total, slot) => total + slot, 0);

            const totalPrice = uniqueSlots
              .filter((slotInfo) => slotInfo.hasOwnProperty("cost"))
              .map((getSoltDataArrayWithSlot) => getSoltDataArrayWithSlot)
              .filter((slot) => slot.slotNumber > 0)
              .map(({ cost }) => cost)
              .reduce((total, slot) => total + slot, 0);

            guest.dateRange[dateKey].totalSlot = totalSlot;
            guest.dateRange[dateKey].totalPrice = totalPrice;
          });

          const getTotalSpots = dateKeys.map(
            (date) => guest.dateRange[date].totalSlot
          );
          const getTotalSpotsPrice = dateKeys.map(
            (date) => guest.dateRange[date].totalPrice
          );

          guest.totalSpots = getTotalSpots.reduce(
            (initialNoSpot, spotValue) => initialNoSpot + spotValue,
            0
          );
          guest.totalPrice = getTotalSpotsPrice.reduce(
            (initialAmount, spotAmount) => initialAmount + spotAmount,
            0
          );
        });

        setMutatedBroadcasterData([...selectedRadioStationInfo, ...newData]);
      }
      // }
    };

    updateRadioStationData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [broadcasterData.isChecked, isChecked]);

  useEffect(() => {
    setIsChecked(false);
  }, [broadcasterData.advertType, broadcasterData.duration]);

  // Get the current date
  const getcurrentDate = new Date();
  getcurrentDate.setDate(getcurrentDate.getDate() + 1); // Set it to a day after the current day

  const handleCheckboxChange = (e) => {
    const id = e.target.value;
    const isSelected = e.target.checked;

    // Find the index of the slotData in slotDataList
    const index = slotDataList?.slotData.findIndex((item) => item.id === id);

    if (index !== -1) {
      // Update the isSelected property directly
      slotDataList.slotData[index].isSelected = isSelected;

      if (isSelected) {
        // Restore slotNumber and cost if previously saved
        slotDataList.slotData[index].slotNumber =
          slotDataList.slotData[index].previousSlotNumber || 0; // Default to 0 if previousSlotNumber is not defined
        slotDataList.slotData[index].cost =
          slotDataList.slotData[index].slotCost || 0;
      } else {
        // Save the current slotNumber before setting to 0
        slotDataList.slotData[index].previousSlotNumber =
          slotDataList.slotData[index].slotNumber;
        slotDataList.slotData[index].slotCost =
          slotDataList.slotData[index].cost;
        slotDataList.slotData[index].slotNumber = 0;
        slotDataList.slotData[index].cost = 0; // Remove cost property
      }

      // Update checkedSelectedSlot with the updated object
      setCheckedSelectedSlot({
        ...checkedSelectedSlot,
        slotData: slotDataList.slotData.filter((item) => item.isSelected),
      });

      // Update slotDataList with the modified item
      setSlotDataList((prevSlotDataList) => {
        const updatedSlotDataList = [...prevSlotDataList.slotData];
        updatedSlotDataList[index].isSelected = isSelected;
        return { ...prevSlotDataList, slotData: updatedSlotDataList };
      });

      // Update the dateRange in broadcasterData
      const getRadioStationWithSelectedSlot = mutatedbroadcasterData.filter(
        (radioStation) => radioStation.guest.id === broadcasterId
      );

      const newData = [...getRadioStationWithSelectedSlot];
      newData.forEach(({ guest }) => {
        const dateKeys = Object.keys(guest.dateRange);

        dateKeys.forEach((dateKey) => {
          const slotData = guest.dateRange[dateKey];
          if (slotData === slotDataList.slotData[0].date) {
            const updatedSlot = {
              ...slotData,
              slotData: slotDataList.slotData,
            };

            console.log(updatedSlot, "testing...");
            return updatedSlot;
          }
          const totalSlot = slotDataList.slotData
            .map((slotInfo) => slotInfo.slotNumber)
            .filter((slotNum) => slotNum > 0)
            .reduce((total, slot) => total + slot, 0);

          const totalPrice = slotDataList.slotData
            .map((slotCost) => slotCost.cost)
            .filter((cost) => cost > 0)
            .reduce((total, cost) => total + cost, 0);

          guest.dateRange[slotDataList.slotData[0].date].totalSlot = totalSlot;
          guest.dateRange[slotDataList.slotData[0].date].totalPrice =
            totalPrice;
        });

        const getTotalSpots = dateKeys.map(
          (date) => guest.dateRange[date].totalSlot
        );
        const getTotalSpotsPrice = dateKeys.map(
          (date) => guest.dateRange[date].totalPrice
        );

        guest.totalSpots = getTotalSpots.reduce(
          (initialNoSpot, spotValue) => initialNoSpot + spotValue,
          0
        );
        guest.totalPrice = getTotalSpotsPrice.reduce(
          (initialAmount, spotAmount) => initialAmount + spotAmount,
          0
        );
      });
    }
  };

  const [
    duplicateAcrossMultipleRadioStation,
    setDuplicateAcrossMultipleRadioStation,
  ] = useState(false);

  const fn = (data, copyData) => {
    if (duplicateAcrossMultipleRadioStation) {
      const arr = [];
      const dateKeys = Object.keys(copyData.guest.dateRange);
      for (const eachData of data) {
        const { guest } = eachData;

        if (guest.id === copyData.guest.id) {
          arr.push(eachData);
        } else {
          const newObj = duplicates(copyData.guest, guest);

          arr.push({ guest: { ...guest, dateRange: newObj } });
        }
        arr.forEach(({ guest }) => {
          const dateKeys = Object.keys(guest.dateRange);

          const allSlotsData = Object.values(guest.dateRange)
            .map((slot) => {
              return [...slot.slotData];
            })
            .flat()
            .filter((slotData) => !!slotData.slotNumber)
            .map((slot) => {
              slot = { ...slot };
              slot["advertType"] = getAdvertType();
              slot["language"] = guest?.language;

              return slot;
            });

          const getTotalSpots = dateKeys.map(
            (date) => guest.dateRange[date].totalSlot
          );

          const getTotalSpotsPrice = dateKeys.map(
            (date) => guest.dateRange[date].totalPrice
          );

          guest.allSlots = [...allSlotsData];

          guest.totalSpots = getTotalSpots.reduce(
            (initialNoSpot, spotValue) => initialNoSpot + spotValue
          );
          guest.totalPrice = getTotalSpotsPrice.reduce(
            (initialAmount, spotAmount) => initialAmount + spotAmount
          );
        });
      }

      setMutatedBroadcasterData(arr);
      return true;
    }
  };

  const removeDuplicateHandler = (data, copyData) => {
    // if (!duplicateAcrossMultipleRadioStation) {
    const arr = [];
    const dateKeys = Object.keys(copyData.guest.dateRange);
    for (const eachData of data) {
      const { guest } = eachData;
      if (guest.id === copyData.guest.id) {
        arr.push(eachData);
      } else {
        const { slotData } = guest.dateRange[dateKeys[0]];
        const newSlotData = [];
        slotData.forEach((slot) => {
          delete slot["isSelected"];
          delete slot["cost"];
          const newSlot = {
            ...slot,
            slotNumber: 0,
          };
          newSlotData.push(newSlot);
        });
        const newObj1 = {
          guest: {
            ...guest,
            dateRange: {
              [dateKeys[0]]: {
                slotData: newSlotData,
                totalSlot: 0,
                totalPrice: 0,
              },
            },
            allSlots: [],
          },
        };
        arr.push(newObj1);
      }
      arr.forEach(({ guest }) => {
        const dateKeys = Object.keys(guest.dateRange);

        const allSlotsData = Object.values(guest.dateRange)
          .map((slot) => {
            return [...slot.slotData];
          })
          .flat()
          .filter((slotData) => !!slotData.slotNumber)
          .map((slot) => {
            slot = { ...slot };
            slot["advertType"] = getAdvertType();
            slot["language"] = guest?.language;

            return slot;
          });

        const getTotalSpots = dateKeys.map(
          (date) => guest.dateRange[date].totalSlot
        );

        const getTotalSpotsPrice = dateKeys.map(
          (date) => guest.dateRange[date].totalPrice
        );

        guest.allSlots = [...allSlotsData];

        guest.totalSpots = getTotalSpots.reduce(
          (initialNoSpot, spotValue) => initialNoSpot + spotValue
        );
        guest.totalPrice = getTotalSpotsPrice.reduce(
          (initialAmount, spotAmount) => initialAmount + spotAmount
        );
      });
    }
    setMutatedBroadcasterData(arr);
    return;
  };

  useEffect(() => {
    if (duplicateAcrossMultipleRadioStation) {
      fn(mutatedbroadcasterData, activeBroadcasterStation[0]);
    } else {
      removeDuplicateHandler(
        mutatedbroadcasterData,
        activeBroadcasterStation[0]
      );
    }
  }, [duplicateAcrossMultipleRadioStation]);

  useEffect(() => {
    if (duplicateAcrossMultipleRadioStation) {
      fn(mutatedbroadcasterData, activeBroadcasterStation[0]);
    }
  }, [broadcasterData?.startDate, broadcasterData?.endDate]);

  const duplicateSwitchHandler = () => {
    setDuplicateAcrossMultipleRadioStation((prevState) => !prevState);
  };



  const duplicates = (copyData, pasteData) => {
    const {
      dateRange,
      advertType,
      duration,
      language,
      startDate,
      endDate,
      totalDays,
      isChecked,
    } = copyData;
    const newObj = {};
    const dateKeys = Object.keys(dateRange);
    const pasteDatadateKeys = Object.keys(pasteData.dateRange);
    const { id, dateRange: pasteDataDateRange } = pasteData;
    const firstIndexOfPasteData = pasteDatadateKeys[0];
    const { slotData: pasteSlotData } =
      pasteDataDateRange[firstIndexOfPasteData];

    let cost;

    if (advertType === "Jingle") {
      if (duration === "15Sec") {
        cost = pasteSlotData[0].Jingle15SecPrice;
      }
      if (duration === "30Sec") {
        cost = pasteSlotData[0].Jingle30SecPrice;
      }
      if (duration === "45Sec") {
        cost = pasteSlotData[0].Jingle45SecPrice;
      }
      if (duration === "60Sec") {
        cost = pasteSlotData[0].Jingle60SecPrice;
      }
    }
    if (advertType === "paidAdvertisements") {
      if (duration === "50Words") {
        cost = pasteSlotData[0].Ann50WordsPrice;
      }
      if (duration === "75Words") {
        cost = pasteSlotData[0].Ann75WordsPrice;
      }
      if (duration === "100Words") {
        cost = pasteSlotData[0].Ann100WordsPrice;
      }
    }

    for (const key of dateKeys) {
      // console.log(slotDataArr, "okay..");
      if (pasteData.dateRange.hasOwnProperty(key)) {
        const { slotData } = dateRange[key];
        const { slotData: pasteSlotData } = pasteData.dateRange[key];
        const newSlotData = [];
        for (let i = 0; i < slotData.length; i++) {
          const { isSelected, slotNumber } = slotData[i];
          if (isSelected && slotNumber) {
            const newSlot = {
              ...pasteSlotData[i],
              isSelected,
              slotNumber,
              cost: cost * slotNumber,
            };
            newSlotData.push(newSlot);
          } else {
            newSlotData.push(pasteSlotData[i]);
          }
        }
        newObj[key] = {
          slotData: newSlotData,
        };
      } else {
        const { slotData } = dateRange[key];

        const {
          Jingle15SecPrice,
          Jingle30SecPrice,
          Jingle45SecPrice,
          Jingle60SecPrice,
          Ann50WordsPrice,
          Ann75WordsPrice,
          Ann100WordsPrice,
        } = pasteSlotData[0];
        const newArray = [];
        for (const slot of slotData) {
          const newObj = {
            ...slot,
            broadcasterId: id,
            Ann50WordsPrice,
            Ann75WordsPrice,
            Ann100WordsPrice,
            Jingle15SecPrice,
            Jingle30SecPrice,
            Jingle45SecPrice,
            Jingle60SecPrice,
            cost: cost * slot.slotNumber,
            advertType: advertType + duration,
          };
          newArray.push(newObj);
        }

        newObj[key] = {
          slotData: newArray,
        };
        pasteData.advertType = advertType;
        pasteData.duration = duration;
        pasteData.language = language;
        pasteData.startDate = startDate;
        pasteData.endDate = endDate;
        pasteData.totalDays = totalDays;
        pasteData.isChecked = isChecked
      }
    }

    // Calculate totalSlot for each dateRange key object
    Object.keys(newObj).forEach((dateKey) => {
      const slotData = newObj[dateKey]?.slotData;
      if (slotData) {
        const totalSlot = slotData
          .filter((slot) => slot.slotNumber > 0)
          .reduce((total, slot) => total + slot.slotNumber, 0);

        const totalCost = slotData
          .filter((slot) => slot.slotNumber > 0)
          .reduce((total, slot) => total + (slot.cost || 0), 0);

        newObj[dateKey].totalSlot = totalSlot;
        newObj[dateKey].totalPrice = totalCost;
      }
    });
    return newObj;
  };

  const ModalOpen = () => {
    setTabs(0);
    setOpenSlotModal(true);
  };
  const OpenPrimary = () => {
    setOpenPrimaryModal(true);
  };

  //function to close second modal
  const ClosePrimary = () => {
    setOpenPrimaryModal(false);
  };
  const ModalClose = () => {
    setOpenSlotModal(false);
  };
  const truncateString = (str, num) => {
    if (str && str.length <= num) {
      return str;
    }

    if (str) {
      return str.slice(0, num) + "...";
    }
  };

  const deleteslotForSelectedDate = (date) => {
    const arr = [];

    const currDate = moment(date).format("YYYY-MM-DD");
    const getRadioStationWithSelectedSlotToDelete =
      mutatedbroadcasterData.filter(
        (radioStation) => radioStation.guest.id === broadcasterId
      );
    const selectedRadioStationInfo = mutatedbroadcasterData.filter(
      (radioStation) => radioStation.guest.id !== broadcasterId
    );

    if (duplicateAcrossMultipleRadioStation) {
      // const currDate = moment(date).format("YYYY-MM-DD");
      for (const broadcasterData of mutatedbroadcasterData) {
        // const dateKeys = Object.keys(broadcasterData.guest.dateRange);
        // const { dateRange } = broadcasterData.guest;
        const b = Object.fromEntries(
          Object.entries(broadcasterData.guest.dateRange).filter(
            ([k]) => !date.includes(k)
          )
        );

        if (broadcasterData.guest.dateRange.hasOwnProperty(date)) {
          const { slotData } = broadcasterData.guest.dateRange[date];
          const newSlotData = [];
          slotData.forEach((slot) => {
            delete slot["isSelected"];
            delete slot["cost"];
            const newSlot = {
              ...slot,
              slotNumber: 0,
            };
            newSlotData.push(newSlot);
          });
          const newDateRange = {
            ...b,
            [date]: {
              slotData: newSlotData,
              totalSlot: 0,
              totalPrice: 0,
            },
          };

          // arr.push(newDateRange);

          const newObj1 = {
            guest: {
              ...broadcasterData.guest,
              dateRange: newDateRange,
            },
          };
          arr.push(newObj1);
        }

        arr.forEach(({ guest }) => {
          const dateKeys = Object.keys(guest.dateRange);

          const allSlotsData = Object.values(guest.dateRange)
            .map((slot) => {
              return [...slot.slotData];
            })
            .flat()
            .filter((slotData) => !!slotData.slotNumber)
            .map((slot) => {
              slot = { ...slot };
              slot["advertType"] = getAdvertType();
              slot["language"] = guest?.language;

              return slot;
            });

          const getTotalSpots = dateKeys.map(
            (date) => guest.dateRange[date].totalSlot
          );

          const getTotalSpotsPrice = dateKeys.map(
            (date) => guest.dateRange[date].totalPrice
          );
          guest.allSlots = [...allSlotsData];

          guest.totalSpots = getTotalSpots.reduce(
            (initialNoSpot, spotValue) => initialNoSpot + spotValue
          );
          guest.totalPrice = getTotalSpotsPrice.reduce(
            (initialAmount, spotAmount) => initialAmount + spotAmount
          );
        });
      }
      setMutatedBroadcasterData(arr);
    } else {
      const slotData =
        getRadioStationWithSelectedSlotToDelete[0]?.guest.dateRange[currDate]
          ?.slotData;

      const updatedSlotData = slotData.map((slot) => {
        if (slot.isSelected) {
          return {
            ...slot,
            isSelected: false,
            slotNumber: 0,
            cost: 0,
          };
        }
        return slot;
      });
      const totalSlot = updatedSlotData
        .map((slotInfo) => slotInfo.slotNumber)
        .filter((slotNum) => slotNum > 0)
        .reduce((total, slot) => total + slot, 0);

      const totalPrice = updatedSlotData
        .map((slotCost) => slotCost.cost)
        .filter((cost) => cost > 0)
        .reduce((total, cost) => total + cost, 0);

      const currentSlotInfo =
        getRadioStationWithSelectedSlotToDelete[0]?.guest.dateRange[currDate];

      currentSlotInfo.slotData = updatedSlotData;

      currentSlotInfo.totalSlot = totalSlot;

      currentSlotInfo.totalPrice = totalPrice;
      getRadioStationWithSelectedSlotToDelete.forEach(({ guest }) => {
        const dateKeys = Object.keys(guest.dateRange);
        const getTotalSpots = dateKeys.map(
          (date) => guest.dateRange[date].totalSlot
        );
        const getTotalSpotsPrice = dateKeys.map(
          (date) => guest.dateRange[date].totalPrice
        );
        guest.totalSpots = getTotalSpots.reduce(
          (initialNoSpot, spotValue) => initialNoSpot + spotValue
        );
        guest.totalPrice = getTotalSpotsPrice.reduce(
          (initialAmount, spotAmount) => initialAmount + spotAmount
        );
      });
      setMutatedBroadcasterData([
        getRadioStationWithSelectedSlotToDelete[0],
        ...selectedRadioStationInfo,
      ]);
    }

    ClosePrimary();
  };

  const renderDayContents = (day, date) => {
    // if (duplicateAcrossMultipleRadioStation) {
    //   fn(mutatedbroadcasterData, activeBroadcasterStation[0]);
    // }
    const currDate = moment(date).format("YYYY-MM-DD");
    const isSpecialDate =
      broadcasterData?.dateRange &&
      broadcasterData.dateRange.hasOwnProperty(currDate);

    const slotData =
      broadcasterData?.dateRange &&
      broadcasterData.dateRange[currDate]?.slotData;
    const isSpecial =
      slotData && slotData?.some((slot) => slot.isSelected === true);

    return (
      <div className="day-container">
        {/* {day} */}

        {isSpecialDate && !isSpecial && (
          <div className="special-marker" onClick={ModalOpen}>
            <div className="add_calender_btn">
              <MdControlPoint className="add_calender_btned" />
            </div>
            <div className="calender_date_wrapper">
              <div className="calender_day">
                <p>{day}</p>
              </div>
            </div>
          </div>
        )}

        {isSpecial && (
          <Popover
            initialFocusRef={initialFocusRef}
            placement="top-start"
            closeOnBlur={true}
          >
            <PopoverTrigger>
              <div className="special-marker slot-available">
                <div className="add_calender_btn">
                  <MdControlPoint className="add_calender_btned" />
                </div>
                <div className="calender_date_wrapper">
                  <div className="calender_day">
                    <p>{day}</p>
                  </div>
                  {advertTypeSelected && (
                    <div className="calender_details_wrapper">
                      <span className="calender_eye_icon">
                        <FaEye className="calender_eye_iconed" />
                      </span>
                      <span className="calender_details">
                        <p>
                          {truncateString(
                            `${broadcasterData.duration} ${broadcasterData.advertType}`,
                            12
                          )}
                        </p>
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </PopoverTrigger>
            <PopoverContent className="popover_wrapper">
              <div className="primary_wrapper">
                <div className="primary_edit" onClick={ModalOpen}>
                  <span>
                    <MdOutlineCreate className="primary_edit_icon" />
                  </span>
                  <span className="primary_edit_text">
                    <p>Edit</p>
                  </span>
                </div>
                <div className="primary_delete" onClick={OpenPrimary}>
                  <span>
                    <MdOutlineDelete className="primary_delete_icon" />
                  </span>
                  <span className="primary_delete_text">
                    <p>Delete</p>
                  </span>
                </div>
              </div>
            </PopoverContent>
          </Popover>
        )}
        {!isSpecialDate && (
          <div className="special-marker-color">
            <div className="add_calender_btn">
              <MdControlPoint className="add_calender_btned-gray" />
            </div>
            <div className="calender_date_wrapper-gray">
              <div className="calender_day-gray">
                <p>{day}</p>
              </div>
              {advertTypeSelected && (
                <div className="calender_details_wrapper-gray">
                  <span className="calender_eye_icon-gray">
                    <FaEye className="calender_eye_iconed-gray" />
                  </span>
                  <span className="calender_details-gray">
                    <p>
                      {truncateString(
                        `${bookingInfo.duration} ${bookingInfo.advertType}`,
                        12
                      )}
                    </p>
                  </span>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div id="station-detail-cnt">
      <div id="station-title">
        <div>
          <h2>{title}</h2>
          <h3>{tagline}</h3>
        </div>
        <button onClick={isOpen}>View Time Schedule</button>
      </div>
      <div className="overall_wrapper">
        <div id="select-advert-details">
          <div>
            <label htmlFor="advert-type">Advert Type</label>
            <div>
              <select
                value={broadcasterData?.advertType}
                name="advertType"
                onChange={(e) => handleAdvertType(e)}
              >
                <option value="">select advert type</option>
                {broadcasterData?.advertTypeData?.map((type, index) => (
                  <option value={type.value} key={index}>
                    {type.name}
                  </option>
                ))}
              </select>
            </div>
            <p>Check Time Schedule for shows in your selected time slots.</p>
          </div>
          <div>
            <label htmlFor="duration">Duration</label>
            <div>
              <select
                name="duration"
                onChange={(e) => handleAdvertDur(e)}
                defaultValue="select advert duration"
                value={broadcasterData?.duration}
              >
                <option value="">select advert duration</option>
                {broadcasterData?.advertTypeData &&
                  broadcasterData?.advertTypeData
                    .find((dur) => dur.value === broadcasterData?.advertType)
                    ?.advertTypeDuration?.map((durationType) => (
                      <option value={durationType.val}>
                        {durationType.dur}
                      </option>
                    ))}
              </select>
            </div>
          </div>
          <div>
            <label htmlFor="language">
              Select your preferred language for this campaign
            </label>
            <div>
              <select
                name="language"
                onChange={handleChange}
                value={broadcasterData?.language ?? ""}
              >
                <option value="">select language</option>
                <option value="English">English</option>
                <option value="Pidgin">Pidgin</option>
                <option value="Yoruba">Yoruba</option>
                <option value="Igbo">Igbo</option>
                <option value="Hausa">Hausa</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="border-thin"></div>

      <div className="overall_wrapper">
        <div className="inner-wrap">
          <div id="select-advert-time">
            <h2>
              You have selected {currentNumberofDays} Day
              {currentNumberofDays > 1 ? "s" : ""}
            </h2>

            <div id="select-date">
              <div>
                <label htmlFor="start-date">Start Date</label>
                <div>
                  <input
                    id="start-date"
                    type="date"
                    // min={minDate}
                    min={getcurrentDate.toISOString().slice(0, 10)}
                    ref={startDatePicker}
                    disabled={
                      !broadcasterData?.language || !broadcasterData?.duration
                    }
                    value={moment(broadcasterData?.startDate).format(
                      "YYYY-MM-DD"
                    )}
                    // value={broadcasterData?.startDate}
                    onChange={(e) => onChangeDateHandler(e, "start")}
                  />
                  <input
                    ref={startDateView}
                    className="formatted-date"
                    type="text"
                    placeholder={`${moment(broadcasterData?.startDate).format(
                      "dddd, D MMMM, YYYY"
                    )}`}
                    readOnly
                  />
                </div>
              </div>
              <div>
                <label htmlFor="end-date">End Date</label>
                <div>
                  <input
                    id="end-date"
                    type="date"
                    min={getcurrentDate.toISOString().slice(0, 10)}
                    ref={endDatePicker}
                    disabled={
                      !broadcasterData?.language || !broadcasterData?.duration
                    }
                    // value={broadcasterData?.endDate}
                    value={moment(broadcasterData?.endDate).format(
                      "YYYY-MM-DD"
                    )}
                    onChange={(e) => onChangeDateHandler(e, "end")}
                  />
                  <input
                    ref={endDateView}
                    className="formatted-date"
                    type="text"
                    placeholder={`${moment(broadcasterData?.endDate).format(
                      "dddd, D MMMM, YYYY"
                    )}`}
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
          <div id="set-time-slot">
            <h2>Click on dates to input spots.</h2>
            <>
              <Modal isOpen={openSlotModal} onClose={ModalClose} size={"md"}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>
                    {tabs === 0 && (
                      <ModalHeaderText>Select ad play time</ModalHeaderText>
                    )}
                    {tabs === 1 && (
                      <ModalHeaderText>
                        {/* Input Slot Numbers for {`${moment(currentDate).format("LL")}`} */}

                        <div className="slectedslot_header">
                          <div
                            className="slectedslot_btn"
                            onClick={() => {
                              setTabs(0);
                            }}
                          >
                            <Svg />
                          </div>
                          <div className="selectedslot_txt">
                            <div>Set Ad Quantity</div>
                          </div>
                        </div>
                      </ModalHeaderText>
                    )}
                  </ModalHeader>
                  <ModalCloseButton color="#004643" />
                  <ModalBody>
                    {tabs === 0 && (
                      <SlotContainer>
                        {slotDataList &&
                          slotDataList?.slotData?.map(
                            ({ startTime, endTime, id, isSelected }) => {
                              const checkboxId = `checkbox_${id}`;

                              return (
                                <div className="adchecker" key={id}>
                                  <input
                                    type="checkbox"
                                    className="slotbox"
                                    id={checkboxId}
                                    value={id}
                                    key={id}
                                    checked={isSelected || false}
                                    onChange={(e) => handleCheckboxChange(e)}
                                  />
                                  <label htmlFor={checkboxId}></label>
                                  <InputLeft>
                                    <SlotDataTimeText>{`${moment(
                                      startTime
                                    ).format("LT")}- ${moment(endTime).format(
                                      "LT"
                                    )}`}</SlotDataTimeText>
                                  </InputLeft>
                                </div>
                              );
                            }
                          )}
                      </SlotContainer>
                    )}
                    {tabs === 1 && (
                      <SlotContainerInput>
                        {slotDataList &&
                          slotDataList?.slotData?.map(
                            ({
                              startTime,
                              endTime,
                              id,
                              isSelected,
                              slotNumber = 0,
                              Jingle60SecPrice,
                              Jingle45SecPrice,
                              Jingle30SecPrice,
                              Jingle15SecPrice,
                              Ann50WordsPrice,
                              Ann100WordsPrice,
                              Ann75WordsPrice,
                            }) => {
                              const inputId = `input_${id}`;
                              let price;

                              if (isSelected) {
                                switch (advertTypeSelected) {
                                  case "60Sec":
                                    price = Jingle60SecPrice;
                                    break;
                                  case "45Sec":
                                    price = Jingle45SecPrice;
                                    break;
                                  case "30Sec":
                                    price = Jingle30SecPrice;
                                    break;
                                  case "15Sec":
                                    price = Jingle15SecPrice;
                                    break;
                                  case "50Words":
                                    price = Ann50WordsPrice;
                                    break;
                                  case "100Words":
                                    price = Ann100WordsPrice;
                                    break;
                                  case "75Words":
                                    price = Ann75WordsPrice;
                                    break;
                                  default:
                                    break;
                                }
                              }
                              return (
                                <>
                                  {isSelected && (
                                    <div className="selectedSlot_wrappper">
                                      <div className="selectedTime_wrappper">
                                        <div>
                                          <InputSlotTextSelected>
                                            <SlotDataText>{`${moment(
                                              startTime
                                            ).format("LT")}- ${moment(
                                              endTime
                                            ).format("LT")} `}</SlotDataText>
                                            <div className="add_wrapper">
                                              <p>{price ? `N ${price}` : 0}</p>
                                            </div>
                                          </InputSlotTextSelected>
                                        </div>
                                      </div>
                                      <div className="input_wrappered">
                                        <IncrementDecrement
                                          initialValue={slotNumber}
                                          onChange={
                                            (newSlotNumber) =>
                                              slotInputChangeHandler(
                                                newSlotNumber,
                                                { id }
                                              ) // Update slot on change
                                          }
                                        />
                                      </div>
                                    </div>
                                  )}
                                </>
                              );
                            }
                          )}
                        {slotDataList?.slotData &&
                          slotDataList?.slotData?.filter(
                            (slot) => slot.isSelected
                          ).length === 0 && (
                            <EmptySlotModal>
                              <EmptySlotImg
                                src={EmptyStateGif}
                                alt="Empty State Gif"
                              />
                              <EmptySlotText>
                                No Slot selected. Pick a slot or continue
                              </EmptySlotText>
                            </EmptySlotModal>
                          )}
                      </SlotContainerInput>
                    )}
                  </ModalBody>

                  <ModalFooter>
                    {tabs === 0 && (
                      <div className="checkbox_footer">
                        <Button
                          mr={3}
                          onClick={() => {
                            setTabs(1);
                          }}
                        >
                          Next
                        </Button>
                      </div>
                    )}

                    {tabs === 1 && (
                      <div className="footer_wrap">
                        {slotDataList?.slotData &&
                          slotDataList?.slotData?.filter(
                            (slot) => slot.isSelected
                          ).length === 0 ? (
                          <></>
                        ) : (
                          <div className="custom-checkbox-container">
                            <div
                              className="checkmark-box"
                              onClick={() =>
                                duplicateSelectedSlotsAcrossDates()
                              }
                            >
                              {/* {a && setIsCheckedForActiveRadiostation()} */}
                              <span
                                className={
                                  activeStation.guest.isChecked &&
                                  "checkmark-tick"
                                }
                              ></span>
                            </div>
                            <p>
                              D
                              <span style={{ textTransform: "lowercase" }}>
                                uplicate order across chosen dates
                              </span>
                            </p>
                          </div>
                        )}
                        <div className="checkbox_footer">
                          <Button mr={3} onClick={saveHandler}>
                            {slotDataList?.slotData &&
                              slotDataList?.slotData.filter(
                                (slot) => slot.isSelected
                              ).length === 0
                              ? "Continue"
                              : "Done"}
                          </Button>
                        </div>
                      </div>
                    )}
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </>
            <>
              <Modal
                isOpen={openPrimaryModal}
                onClose={ClosePrimary}
                size={"md"}
              >
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>
                    <ModalHeaderText>Delete Booking</ModalHeaderText>
                  </ModalHeader>
                  <ModalCloseButton color="#000000" />
                  <ModalBody>
                    <div className="delete_body">
                      <p>
                        Are{" "}
                        <span className="lower_case">
                          you sure you want to delete the booking for
                        </span>{" "}
                        {bookingInfo.duration} {bookingInfo.advertType} on{" "}
                        {moment(currentDate).format("dddd, D MMMM, YYYY")}?
                      </p>
                    </div>
                  </ModalBody>

                  <ModalFooter>
                    <div className="delete_wrapper">
                      <div className="delete-inner">
                        <div className="canceled_footer" onClick={ClosePrimary}>
                          <p>Cancel</p>
                        </div>
                        <div
                          className="deleted_wrapper"
                          onClick={() => deleteslotForSelectedDate(currentDate)}
                        >
                          <p>YES, DELETE</p>
                        </div>
                      </div>
                    </div>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </>
            <>
              <Modal isOpen={isOpenChakra} onClose={onClose} size={"md"}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>
                    <ModalHeaderText>
                      {/* Input Slot Numbers for {`${moment(currentDate).format("LL")}`} */}

                      <div className="slectedslot_header">
                        <div
                          className="slectedslot_btn"
                          onClick={() => {
                            onClose();
                            ModalOpen();
                          }}
                        >
                          <Svg />
                        </div>
                        <div className="selectedslot_txt">
                          <div>Set Ad Quantity</div>
                        </div>
                      </div>
                    </ModalHeaderText>
                  </ModalHeader>
                  {/* <ModalCloseButton /> */}
                  <ModalBody>
                    <SlotContainerInput>
                      {slotDataList &&
                        slotDataList?.slotData?.map(
                          ({
                            startTime,
                            endTime,
                            id,
                            isSelected,
                            slotNumber = 0,
                            Jingle60SecPrice,
                            Jingle45SecPrice,
                            Jingle30SecPrice,
                            Jingle15SecPrice,
                            Ann50WordsPrice,
                            Ann100WordsPrice,
                            Ann75WordsPrice,
                          }) => {
                            const inputId = `input_${id}`;
                            let price;

                            if (isSelected) {
                              switch (advertTypeSelected) {
                                case "60Sec":
                                  price = Jingle60SecPrice;
                                  break;
                                case "45Sec":
                                  price = Jingle45SecPrice;
                                  break;
                                case "30Sec":
                                  price = Jingle30SecPrice;
                                  break;
                                case "15Sec":
                                  price = Jingle15SecPrice;
                                  break;
                                case "50Words":
                                  price = Ann50WordsPrice;
                                  break;
                                case "100Words":
                                  price = Ann100WordsPrice;
                                  break;
                                case "75Words":
                                  price = Ann75WordsPrice;
                                  break;
                                default:
                                  break;
                              }
                            }
                            return (
                              <>
                                {isSelected && (
                                  <div className="selectedSlot_wrappper">
                                    <div className="selectedTime_wrappper">
                                      <div>
                                        <InputSlotTextSelected>
                                          <SlotDataText>{`${moment(
                                            startTime
                                          ).format("LT")}- ${moment(
                                            endTime
                                          ).format("LT")} `}</SlotDataText>
                                          <div className="add_wrapper">
                                            <p>{price ? `N ${price}` : 0}</p>
                                          </div>
                                        </InputSlotTextSelected>
                                      </div>
                                    </div>
                                    <div className="input_wrappered">
                                      <IncrementDecrement
                                        initialValue={slotNumber}
                                        onChange={
                                          (newSlotNumber) =>
                                            slotInputChangeHandler(
                                              newSlotNumber,
                                              { id }
                                            ) // Update slot on change
                                        }
                                      />
                                    </div>
                                  </div>
                                )}
                              </>
                            );
                          }
                        )}
                      {slotDataList?.slotData &&
                        slotDataList?.slotData?.filter(
                          (slot) => slot.isSelected
                        ).length === 0 && (
                          <EmptySlotModal>
                            <EmptySlotImg
                              src={EmptyStateGif}
                              alt="Empty State Gif"
                            />
                            <EmptySlotText>
                              No Slot selected. Pick a slot or continue
                            </EmptySlotText>
                          </EmptySlotModal>
                        )}
                    </SlotContainerInput>
                  </ModalBody>

                  <ModalFooter>
                    <div className="footer_wrap">
                      <div className="custom-checkbox-container">
                        <div
                          className="checkmark-box"
                          onClick={() => duplicateSelectedSlotsAcrossDates()}
                        >
                          {/* {a && setIsCheckedForActiveRadiostation()} */}
                          <span
                            className={
                              broadcasterData.isChecked && "checkmark-tick"
                            }
                          ></span>
                        </div>
                        <p>
                          D
                          <span style={{ textTransform: "lowercase" }}>
                            uplicate order across chosen dates
                          </span>
                        </p>
                      </div>
                      <div className="checkbox_footer">
                        <Button mr={3} onClick={saveHandler}>
                          {slotDataList?.slotData &&
                            slotDataList?.slotData.filter(
                              (slot) => slot.isSelected
                            ).length === 0
                            ? "Continue"
                            : "Done"}
                        </Button>
                      </div>
                    </div>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </>
            <DatePicker
              selected={broadcasterData?.startDate}
              startDate={broadcasterData?.startDate}
              endDate={broadcasterData?.endDate}
              maxDate={broadcasterData?.endDate}
              minDate={broadcasterData?.startDate}
              onSelect={(date) => {
                let currDate = moment(date).format("YYYY-MM-DD");
                const currSlotDataList =
                  broadcasterData?.dateRange[`${currDate}`];
                setCurrentDate(currDate);
                setSlotDataList(currSlotDataList);
                console.log(activeBroadcasterStation);
              }}
              selectsRange
              inline
              selectsDisabledDaysInRange
              excludeDates={
                (!broadcasterData?.duration || !broadcasterData?.language) && [
                  broadcasterData?.startDate,
                  broadcasterData?.endDate,
                ]
              }
              renderDayContents={renderDayContents}
              calendarClassName="custom-calendar"
            />
          </div>

          {mutatedbroadcasterData.length > 1 && (
            <div className="repeat_wrapper">
              <span className="repeat-text">
                <p>Repeat selected dates across radio stations</p>
              </span>
              <span>
                <Switch
                  onChange={duplicateSwitchHandler}
                  colorScheme="orange"
                  css={css`
                    .chakra-switch__track {
                      background-color: #f1613f; /* Default background color for the switch */
                    }
                    .chakra-switch__thumb {
                      background-color: #ffff; /* Default thumb color for the switch */
                    }
                    &[data-checked] .chakra-switch__track {
                      background-color: #dd6b20; /* Background color when switch is checked */
                    }
                    &[data-checked] .chakra-switch__thumb {
                      background-color: #000; /* Thumb color when switch is checked */
                    }
                  `}
                  className="switch-icon"
                />
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StationDetails;

const SlotContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 15px;
  row-gap: 15px;
  height: 270px;
  overflow-y: scroll;
  margin-top: 30px;
  @media (min-width: 500px) {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 30px;
  }
`;

const SlotContainerInput = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 15px;
  max-height: 270px;
  overflow-y: auto;
`;

const InputContainer = styled.div`
  width: 213px;
  height: 44px;
  border: 0.8px solid #aba8a8;
  border-radius: 2px;
  display: flex;
`;

const InputLeft = styled.div`
  width: 144px;
  height: 44px;
  border-radius: 2px 0px 0px 2px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const InputSlotText = styled.div`
  width: 144px;
  height: 60px;
  background: #ededed;
  border-radius: 2px 0px 0px 2px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  padding: 5px;
`;
const InputSlotTextSelected = styled.div`
  width: 144px;
  height: 60px;
  border-radius: 2px 0px 0px 2px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  padding: 5px;
`;

const SlotDataTimeText = styled.p`
  color: #000000;
  font-family: var(--font-family-Telegraf);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
`;
const SlotDataText = styled.p`
  font-family: var(--font-family-Telegraf);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  /* identical to box height */
  color: #000000;
`;

const ModalHeaderText = styled.h2`
  font-family: var(--font-family-Telegraf);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
`;

const EmptySlotModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-left: 20px;
`;

const EmptySlotImg = styled.img`
  width: 150px;
  height: 150px;
`;

const EmptySlotText = styled.p`
  font-size: 16px;
  font-weight: bold;
  text-wrap: nowrap;
  text-align: center;
`;
