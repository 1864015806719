import {
  DashboardHomeIcon,
  CampaignsIcon,
  DraftsIcon,
  WalletIcon,
  AnalyticsIcon,
  ProfileIcon,
  StatesIcon,
  OrdersIcon,
  FeesIcon,
  AdvertiserIcon,
  BlogIcon,
  RolesIcon,
  TransactionsIcon,
  SettingsIcon,
} from "../pages/ResponsiveAdvertiserDashboard/ResponsiveAdvertiserDashboardLayout/components";

export const menuList = [
  {
    Icon: DashboardHomeIcon,
    text: "Advertiser Dashboard",
    link: "/dashboard",
  },
  {
    Icon: CampaignsIcon,
    text: "Campaigns",
    link: "/dashboard/station",
  },
  {
    Icon: DraftsIcon,
    text: "Drafts",
    link: "/dashboard/draft",
  },
  {
    Icon: WalletIcon,
    text: "Wallet",
    link: "/dashboard/wallet",
  },
  {
    Icon: AnalyticsIcon,
    text: "Analytics",
    link: "/analytics",
  },
  {
    Icon: ProfileIcon,
    text: "Profile",
    link: "/dashboard/profile",
  },
];

export const AdminMenuList = [
  {
    Icon: DashboardHomeIcon,
    text: "Dashboard",
    link: "/admin/dashboard",
  },
  {
    Icon: RolesIcon,
    text: "Roles",
    link: "/admin/roles",
  },
  {
    Icon: DraftsIcon,
    text: "Radio Station",
    link: "/admin/radio-station",
  },
  {
    Icon: AdvertiserIcon,
    text: "Advertiser",
    link: "/admin/advertiser",
  },
  {
    Icon: TransactionsIcon,
    text: "Transactions",
    link: "/admin/transaction",
  },
  {
    Icon: BlogIcon,
    text: "Blog",
    link: "/",
  },
  {
    Icon: StatesIcon,
    text: "States",
    link: "/admin/states",
  },
  {
    Icon: OrdersIcon,
    text: "Orders",
    link: "/admin/orders",
  },
  {
    Icon: SettingsIcon,
    text: "Page Settings",
    link: "/",
  },
  {
    Icon: FeesIcon,
    text: "Platform Fees",
    link: "/admin/platform-fees",
  },
];

export const ordersTableData = [
  {
    id: "1",
    station: "Lagos Talks 91.3 FM",
    email: "Thisismymail@gmail.com",
    phone: "08057527337",
    date: "2021-08-16 06:50:31",
    published: "publsihed",
    status: "inactive",
  },
  {
    id: "1",
    station: "Lagos Talks 91.3 FM",
    email: "Thisismymail@gmail.com",
    phone: "08057527337",
    date: "2021-08-16 06:50:31",
    published: "publsihed",
    status: "active",
  },
  {
    id: "1",
    station: "Lagos Talks 91.3 FM",
    email: "Thisismymail@gmail.com",
    phone: "08057527337",
    date: "2021-08-16 06:50:31",
    published: "publsihed",
    status: "active",
  },
  {
    id: "1",
    station: "Lagos Talks 91.3 FM",
    email: "Thisismymail@gmail.com",
    phone: "08057527337",
    date: "2021-08-16 06:50:31",
    published: "publsihed",
    status: "active",
  },
  {
    id: "1",
    station: "Lagos Talks 91.3 FM",
    email: "Thisismymail@gmail.com",
    phone: "08057527337",
    date: "2021-08-16 06:50:31",
    published: "publsihed",
    status: "active",
  },
  {
    id: "1",
    station: "Lagos Talks 91.3 FM",
    email: "Thisismymail@gmail.com",
    phone: "08057527337",
    date: "2021-08-16 06:50:31",
    published: "publsihed",
    status: "active",
  },
  {
    id: "1",
    station: "Lagos Talks 91.3 FM",
    email: "Thisismymail@gmail.com",
    phone: "08057527337",
    date: "2021-08-16 06:50:31",
    published: "publsihed",
    status: "active",
  },
  {
    id: "1",
    station: "Lagos Talks 91.3 FM",
    email: "Thisismymail@gmail.com",
    phone: "08057527337",
    date: "2021-08-16 06:50:31",
    published: "publsihed",
    status: "active",
  },
  {
    id: "1",
    station: "Lagos Talks 91.3 FM",
    email: "Thisismymail@gmail.com",
    phone: "08057527337",
    date: "2021-08-16 06:50:31",
    published: "publsihed",
    status: "active",
  },
  {
    id: "1",
    station: "Lagos Talks 91.3 FM",
    email: "Thisismymail@gmail.com",
    phone: "08057527337",
    date: "2021-08-16 06:50:31",
    published: "publsihed",
    status: "active",
  },
];

export const dummyCampaignDetails = {
  id: "47",
  startDate: "2023-11-18T00:00:00.000Z",
  endDate: "2023-11-18T00:00:00.000Z",
  campaignDocument: [
    {
      __typename: "CampaignDocument",
      name: "12-f26748a8-c330-42f8-9b4f-9c15ac1ecb3a-file_example_MP3_700KB.wav",
      url: "https://radioadspreadblob.blob.core.windows.net/jingle-audio/12-f26748a8-c330-42f8-9b4f-9c15ac1ecb3a-file_example_MP3_700KB.wav?sv=2021-10-04&st=2023-12-07T10%3A39%3A05Z&se=2023-12-07T11%3A39%3A05Z&sr=b&sp=r&sig=KLZdirim6E5kOkjQ2iaIqBmsqHU5zqUmO6H9Efauo8s%3D",
    },
  ],
  radioStationDetails: [
    {
      __typename: "RadioStationDetails",
      id: "2",
      radioStation: "Breitenberg Inc",
      advertType: "Jingle",
      duration: "15 Sec",
      language: "English",
      numberOfDays: 1,
      numberOfSpots: 1,
      totalAmount: 3198.125,
      status: "Pending",
      paymentMethod: "Paystack",
      bookedSlots: {
        __typename: "BookedSlots",
        startDate: "2023-11-18T00:00:00.000Z",
        endDate: "2023-11-18T00:00:00.000Z",
        slots: [
          {
            __typename: "SlotDetail",
            advertType: "Jingle15Sec",
            date: "2023-11-18T00:00:00.000Z",
            quantity: 1,
            language: "English",
            startTime: "1970-01-01T00:00:00.503Z",
            endTime: "1970-01-01T00:59:00.503Z",
          },
        ],
        bookingDetails: {
          __typename: "BookingDetails",
          totalNoOfDays: 1,
          totalNoOfSpots: 1,
          totalNoOfSlots: 1,
        },
      },
    },
  ],
};

export const slotData = {
  getAllSlot: [
    {
      __typename: "Slot",
      startTime: "1970-01-01T00:00:00.503Z",
      id: "1",
      endTime: "1970-01-01T00:59:00.503Z",
      broadcasterId: "2",
      Jingle60SecPrice: 5000,
      Jingle45SecPrice: 5000,
      Jingle30SecPrice: 5000,
      Jingle15SecPrice: 8000,
      Ann75WordsPrice: 5000,
      Ann50WordsPrice: 7000,
      Ann100WordsPrice: 5000,
    },
    {
      __typename: "Slot",
      startTime: "1970-01-01T01:00:00.503Z",
      id: "2",
      endTime: "1970-01-01T01:59:00.503Z",
      broadcasterId: "2",
      Jingle60SecPrice: 5000,
      Jingle45SecPrice: 5000,
      Jingle30SecPrice: 5000,
      Jingle15SecPrice: 5000,
      Ann75WordsPrice: 5000,
      Ann50WordsPrice: 5000,
      Ann100WordsPrice: 5000,
    },
    {
      __typename: "Slot",
      startTime: "1970-01-01T02:00:00.503Z",
      id: "3",
      endTime: "1970-01-01T02:59:00.503Z",
      broadcasterId: "2",
      Jingle60SecPrice: 5000,
      Jingle45SecPrice: 5000,
      Jingle30SecPrice: 5000,
      Jingle15SecPrice: 5000,
      Ann75WordsPrice: 5000,
      Ann50WordsPrice: 5000,
      Ann100WordsPrice: 5000,
    },
    {
      __typename: "Slot",
      startTime: "1970-01-01T03:00:00.503Z",
      id: "4",
      endTime: "1970-01-01T03:59:00.503Z",
      broadcasterId: "2",
      Jingle60SecPrice: 5000,
      Jingle45SecPrice: 5000,
      Jingle30SecPrice: 5000,
      Jingle15SecPrice: 5000,
      Ann75WordsPrice: 5000,
      Ann50WordsPrice: 5000,
      Ann100WordsPrice: 5000,
    },
    {
      __typename: "Slot",
      startTime: "1970-01-01T04:00:00.503Z",
      id: "5",
      endTime: "1970-01-01T04:59:00.503Z",
      broadcasterId: "2",
      Jingle60SecPrice: 5000,
      Jingle45SecPrice: 5000,
      Jingle30SecPrice: 5000,
      Jingle15SecPrice: 5000,
      Ann75WordsPrice: 5000,
      Ann50WordsPrice: 5000,
      Ann100WordsPrice: 5000,
    },
    {
      __typename: "Slot",
      startTime: "1970-01-01T05:00:00.503Z",
      id: "6",
      endTime: "1970-01-01T05:59:00.503Z",
      broadcasterId: "2",
      Jingle60SecPrice: 5000,
      Jingle45SecPrice: 5000,
      Jingle30SecPrice: 5000,
      Jingle15SecPrice: 5000,
      Ann75WordsPrice: 5000,
      Ann50WordsPrice: 5000,
      Ann100WordsPrice: 5000,
    },
    {
      __typename: "Slot",
      startTime: "1970-01-01T06:00:00.503Z",
      id: "7",
      endTime: "1970-01-01T06:59:00.503Z",
      broadcasterId: "2",
      Jingle60SecPrice: 5000,
      Jingle45SecPrice: 5000,
      Jingle30SecPrice: 5000,
      Jingle15SecPrice: 5000,
      Ann75WordsPrice: 5000,
      Ann50WordsPrice: 5000,
      Ann100WordsPrice: 5000,
    },
    {
      __typename: "Slot",
      startTime: "1970-01-01T07:00:00.503Z",
      id: "8",
      endTime: "1970-01-01T07:59:00.503Z",
      broadcasterId: "2",
      Jingle60SecPrice: 5000,
      Jingle45SecPrice: 5000,
      Jingle30SecPrice: 5000,
      Jingle15SecPrice: 5000,
      Ann75WordsPrice: 5000,
      Ann50WordsPrice: 5000,
      Ann100WordsPrice: 5000,
    },
    {
      __typename: "Slot",
      startTime: "1970-01-01T08:00:00.503Z",
      id: "9",
      endTime: "1970-01-01T08:59:00.503Z",
      broadcasterId: "2",
      Jingle60SecPrice: 5000,
      Jingle45SecPrice: 5000,
      Jingle30SecPrice: 5000,
      Jingle15SecPrice: 5000,
      Ann75WordsPrice: 5000,
      Ann50WordsPrice: 5000,
      Ann100WordsPrice: 5000,
    },
    {
      __typename: "Slot",
      startTime: "1970-01-01T09:00:00.503Z",
      id: "10",
      endTime: "1970-01-01T09:59:00.503Z",
      broadcasterId: "2",
      Jingle60SecPrice: 5000,
      Jingle45SecPrice: 5000,
      Jingle30SecPrice: 5000,
      Jingle15SecPrice: 5000,
      Ann75WordsPrice: 5000,
      Ann50WordsPrice: 5000,
      Ann100WordsPrice: 5000,
    },
    {
      __typename: "Slot",
      startTime: "1970-01-01T10:00:00.503Z",
      id: "11",
      endTime: "1970-01-01T10:59:00.503Z",
      broadcasterId: "2",
      Jingle60SecPrice: 5000,
      Jingle45SecPrice: 5000,
      Jingle30SecPrice: 5000,
      Jingle15SecPrice: 5000,
      Ann75WordsPrice: 5000,
      Ann50WordsPrice: 5000,
      Ann100WordsPrice: 5000,
    },
    {
      __typename: "Slot",
      startTime: "1970-01-01T11:00:00.503Z",
      id: "12",
      endTime: "1970-01-01T11:59:00.503Z",
      broadcasterId: "2",
      Jingle60SecPrice: 5000,
      Jingle45SecPrice: 5000,
      Jingle30SecPrice: 5000,
      Jingle15SecPrice: 5000,
      Ann75WordsPrice: 5000,
      Ann50WordsPrice: 5000,
      Ann100WordsPrice: 5000,
    },
    {
      __typename: "Slot",
      startTime: "1970-01-01T12:00:00.503Z",
      id: "13",
      endTime: "1970-01-01T12:59:00.503Z",
      broadcasterId: "2",
      Jingle60SecPrice: 5000,
      Jingle45SecPrice: 5000,
      Jingle30SecPrice: 5000,
      Jingle15SecPrice: 5000,
      Ann75WordsPrice: 5000,
      Ann50WordsPrice: 5000,
      Ann100WordsPrice: 5000,
    },
    {
      __typename: "Slot",
      startTime: "1970-01-01T13:00:00.503Z",
      id: "14",
      endTime: "1970-01-01T13:59:00.503Z",
      broadcasterId: "2",
      Jingle60SecPrice: 5000,
      Jingle45SecPrice: 5000,
      Jingle30SecPrice: 5000,
      Jingle15SecPrice: 5000,
      Ann75WordsPrice: 5000,
      Ann50WordsPrice: 5000,
      Ann100WordsPrice: 5000,
    },
    {
      __typename: "Slot",
      startTime: "1970-01-01T14:00:00.503Z",
      id: "15",
      endTime: "1970-01-01T14:59:00.503Z",
      broadcasterId: "2",
      Jingle60SecPrice: 5000,
      Jingle45SecPrice: 5000,
      Jingle30SecPrice: 5000,
      Jingle15SecPrice: 5000,
      Ann75WordsPrice: 5000,
      Ann50WordsPrice: 5000,
      Ann100WordsPrice: 5000,
    },
    {
      __typename: "Slot",
      startTime: "1970-01-01T15:00:00.503Z",
      id: "16",
      endTime: "1970-01-01T15:59:00.503Z",
      broadcasterId: "2",
      Jingle60SecPrice: 5000,
      Jingle45SecPrice: 5000,
      Jingle30SecPrice: 5000,
      Jingle15SecPrice: 5000,
      Ann75WordsPrice: 5000,
      Ann50WordsPrice: 5000,
      Ann100WordsPrice: 5000,
    },
    {
      __typename: "Slot",
      startTime: "1970-01-01T16:00:00.503Z",
      id: "17",
      endTime: "1970-01-01T16:59:00.503Z",
      broadcasterId: "2",
      Jingle60SecPrice: 5000,
      Jingle45SecPrice: 5000,
      Jingle30SecPrice: 5000,
      Jingle15SecPrice: 5000,
      Ann75WordsPrice: 5000,
      Ann50WordsPrice: 5000,
      Ann100WordsPrice: 5000,
    },
    {
      __typename: "Slot",
      startTime: "1970-01-01T17:00:00.503Z",
      id: "18",
      endTime: "1970-01-01T17:59:00.503Z",
      broadcasterId: "2",
      Jingle60SecPrice: 5000,
      Jingle45SecPrice: 5000,
      Jingle30SecPrice: 5000,
      Jingle15SecPrice: 5000,
      Ann75WordsPrice: 5000,
      Ann50WordsPrice: 5000,
      Ann100WordsPrice: 5000,
    },
    {
      __typename: "Slot",
      startTime: "1970-01-01T18:00:00.503Z",
      id: "19",
      endTime: "1970-01-01T18:59:00.503Z",
      broadcasterId: "2",
      Jingle60SecPrice: 5000,
      Jingle45SecPrice: 5000,
      Jingle30SecPrice: 5000,
      Jingle15SecPrice: 5000,
      Ann75WordsPrice: 5000,
      Ann50WordsPrice: 5000,
      Ann100WordsPrice: 5000,
    },
    {
      __typename: "Slot",
      startTime: "1970-01-01T19:00:00.503Z",
      id: "20",
      endTime: "1970-01-01T19:59:00.503Z",
      broadcasterId: "2",
      Jingle60SecPrice: 5000,
      Jingle45SecPrice: 5000,
      Jingle30SecPrice: 5000,
      Jingle15SecPrice: 5000,
      Ann75WordsPrice: 5000,
      Ann50WordsPrice: 5000,
      Ann100WordsPrice: 5000,
    },
    {
      __typename: "Slot",
      startTime: "1970-01-01T20:00:00.503Z",
      id: "21",
      endTime: "1970-01-01T20:59:00.503Z",
      broadcasterId: "2",
      Jingle60SecPrice: 5000,
      Jingle45SecPrice: 5000,
      Jingle30SecPrice: 5000,
      Jingle15SecPrice: 5000,
      Ann75WordsPrice: 5000,
      Ann50WordsPrice: 5000,
      Ann100WordsPrice: 5000,
    },
    {
      __typename: "Slot",
      startTime: "1970-01-01T21:00:00.503Z",
      id: "22",
      endTime: "1970-01-01T21:59:00.503Z",
      broadcasterId: "2",
      Jingle60SecPrice: 5000,
      Jingle45SecPrice: 5000,
      Jingle30SecPrice: 5000,
      Jingle15SecPrice: 5000,
      Ann75WordsPrice: 5000,
      Ann50WordsPrice: 5000,
      Ann100WordsPrice: 5000,
    },
    {
      __typename: "Slot",
      startTime: "1970-01-01T22:00:00.503Z",
      id: "23",
      endTime: "1970-01-01T22:59:00.503Z",
      broadcasterId: "2",
      Jingle60SecPrice: 5000,
      Jingle45SecPrice: 5000,
      Jingle30SecPrice: 5000,
      Jingle15SecPrice: 5000,
      Ann75WordsPrice: 5000,
      Ann50WordsPrice: 5000,
      Ann100WordsPrice: 5000,
    },
    {
      __typename: "Slot",
      startTime: "1970-01-01T23:00:00.503Z",
      id: "24",
      endTime: "1970-01-01T23:59:00.503Z",
      broadcasterId: "2",
      Jingle60SecPrice: 5000,
      Jingle45SecPrice: 5000,
      Jingle30SecPrice: 5000,
      Jingle15SecPrice: 5000,
      Ann75WordsPrice: 5000,
      Ann50WordsPrice: 5000,
      Ann100WordsPrice: 5000,
    },
  ],
};

export const listOfDiscount = [
  {
    __typename: "ListOfDiscount",
    amount: "NGN45,000 - NGN50,000",
    discount: 30,
  },
  {
    __typename: "ListOfDiscount",
    amount: "NGN10 - NGN10,000",
    discount: 10,
  },
  {
    __typename: "ListOfDiscount",
    amount: "NGN0 - NGN8,000",
    discount: 70,
  },
  {
    __typename: "ListOfDiscount",
    amount: "NGN0 - NGN20,000",
    discount: 80,
  },
];

export const AdvertPrices = [
  {
    id: "1",
    Jingle60SecPrice: 5000,
    Jingle45SecPrice: 5000,
    Jingle30SecPrice: 5000,
    Jingle15SecPrice: 8000,
    Ann75WordsPrice: 5000,
    Ann50WordsPrice: 7000,
    Ann100WordsPrice: 5000,
  },
  {
    id: "2",
    Jingle60SecPrice: 5000,
    Jingle45SecPrice: 5000,
    Jingle30SecPrice: 5000,
    Jingle15SecPrice: 5000,
    Ann75WordsPrice: 5000,
    Ann50WordsPrice: 5000,
    Ann100WordsPrice: 5000,
  },
  {
    id: "3",
    Jingle60SecPrice: 5000,
    Jingle45SecPrice: 5000,
    Jingle30SecPrice: 5000,
    Jingle15SecPrice: 5000,
    Ann75WordsPrice: 5000,
    Ann50WordsPrice: 5000,
    Ann100WordsPrice: 5000,
  },
  {
    id: "4",
    Jingle60SecPrice: 5000,
    Jingle45SecPrice: 5000,
    Jingle30SecPrice: 5000,
    Jingle15SecPrice: 5000,
    Ann75WordsPrice: 5000,
    Ann50WordsPrice: 5000,
    Ann100WordsPrice: 5000,
  },
  {
    id: "5",
    Jingle60SecPrice: 5000,
    Jingle45SecPrice: 5000,
    Jingle30SecPrice: 5000,
    Jingle15SecPrice: 5000,
    Ann75WordsPrice: 5000,
    Ann50WordsPrice: 5000,
    Ann100WordsPrice: 5000,
  },
  {
    id: "6",
    Jingle60SecPrice: 5000,
    Jingle45SecPrice: 5000,
    Jingle30SecPrice: 5000,
    Jingle15SecPrice: 5000,
    Ann75WordsPrice: 5000,
    Ann50WordsPrice: 5000,
    Ann100WordsPrice: 5000,
  },
  {
    id: "7",
    Jingle60SecPrice: 5000,
    Jingle45SecPrice: 5000,
    Jingle30SecPrice: 5000,
    Jingle15SecPrice: 5000,
    Ann75WordsPrice: 5000,
    Ann50WordsPrice: 5000,
    Ann100WordsPrice: 5000,
  },
  {
    id: "8",
    Jingle60SecPrice: 5000,
    Jingle45SecPrice: 5000,
    Jingle30SecPrice: 5000,
    Jingle15SecPrice: 5000,
    Ann75WordsPrice: 5000,
    Ann50WordsPrice: 5000,
    Ann100WordsPrice: 5000,
  },
  {
    id: "9",
    Jingle60SecPrice: 5000,
    Jingle45SecPrice: 5000,
    Jingle30SecPrice: 5000,
    Jingle15SecPrice: 5000,
    Ann75WordsPrice: 5000,
    Ann50WordsPrice: 5000,
    Ann100WordsPrice: 5000,
  },
  {
    id: "10",
    Jingle60SecPrice: 5000,
    Jingle45SecPrice: 5000,
    Jingle30SecPrice: 5000,
    Jingle15SecPrice: 5000,
    Ann75WordsPrice: 5000,
    Ann50WordsPrice: 5000,
    Ann100WordsPrice: 5000,
  },
  {
    id: "11",
    Jingle60SecPrice: 5000,
    Jingle45SecPrice: 5000,
    Jingle30SecPrice: 5000,
    Jingle15SecPrice: 5000,
    Ann75WordsPrice: 5000,
    Ann50WordsPrice: 5000,
    Ann100WordsPrice: 5000,
  },
  {
    id: "12",
    Jingle60SecPrice: 5000,
    Jingle45SecPrice: 5000,
    Jingle30SecPrice: 5000,
    Jingle15SecPrice: 5000,
    Ann75WordsPrice: 5000,
    Ann50WordsPrice: 5000,
    Ann100WordsPrice: 5000,
  },
  {
    id: "13",
    Jingle60SecPrice: 5000,
    Jingle45SecPrice: 5000,
    Jingle30SecPrice: 5000,
    Jingle15SecPrice: 5000,
    Ann75WordsPrice: 5000,
    Ann50WordsPrice: 5000,
    Ann100WordsPrice: 5000,
  },
  {
    id: "14",
    Jingle60SecPrice: 5000,
    Jingle45SecPrice: 5000,
    Jingle30SecPrice: 5000,
    Jingle15SecPrice: 5000,
    Ann75WordsPrice: 5000,
    Ann50WordsPrice: 5000,
    Ann100WordsPrice: 5000,
  },
  {
    id: "15",
    Jingle60SecPrice: 5000,
    Jingle45SecPrice: 5000,
    Jingle30SecPrice: 5000,
    Jingle15SecPrice: 5000,
    Ann75WordsPrice: 5000,
    Ann50WordsPrice: 5000,
    Ann100WordsPrice: 5000,
  },
  {
    id: "16",
    Jingle60SecPrice: 5000,
    Jingle45SecPrice: 5000,
    Jingle30SecPrice: 5000,
    Jingle15SecPrice: 5000,
    Ann75WordsPrice: 5000,
    Ann50WordsPrice: 5000,
    Ann100WordsPrice: 5000,
  },
  {
    id: "17",
    Jingle60SecPrice: 5000,
    Jingle45SecPrice: 5000,
    Jingle30SecPrice: 5000,
    Jingle15SecPrice: 5000,
    Ann75WordsPrice: 5000,
    Ann50WordsPrice: 5000,
    Ann100WordsPrice: 5000,
  },
  {
    id: "18",
    Jingle60SecPrice: 5000,
    Jingle45SecPrice: 5000,
    Jingle30SecPrice: 5000,
    Jingle15SecPrice: 5000,
    Ann75WordsPrice: 5000,
    Ann50WordsPrice: 5000,
    Ann100WordsPrice: 5000,
  },
  {
    id: "19",
    Jingle60SecPrice: 5000,
    Jingle45SecPrice: 5000,
    Jingle30SecPrice: 5000,
    Jingle15SecPrice: 5000,
    Ann75WordsPrice: 5000,
    Ann50WordsPrice: 5000,
    Ann100WordsPrice: 5000,
  },
  {
    id: "20",
    Jingle60SecPrice: 5000,
    Jingle45SecPrice: 5000,
    Jingle30SecPrice: 5000,
    Jingle15SecPrice: 5000,
    Ann75WordsPrice: 5000,
    Ann50WordsPrice: 5000,
    Ann100WordsPrice: 5000,
  },
  {
    id: "21",
    Jingle60SecPrice: 5000,
    Jingle45SecPrice: 5000,
    Jingle30SecPrice: 5000,
    Jingle15SecPrice: 5000,
    Ann75WordsPrice: 5000,
    Ann50WordsPrice: 5000,
    Ann100WordsPrice: 5000,
  },
  {
    id: "22",
    Jingle60SecPrice: 5000,
    Jingle45SecPrice: 5000,
    Jingle30SecPrice: 5000,
    Jingle15SecPrice: 5000,
    Ann75WordsPrice: 5000,
    Ann50WordsPrice: 5000,
    Ann100WordsPrice: 5000,
  },
  {
    id: "23",
    Jingle60SecPrice: 5000,
    Jingle45SecPrice: 5000,
    Jingle30SecPrice: 5000,
    Jingle15SecPrice: 5000,
    Ann75WordsPrice: 5000,
    Ann50WordsPrice: 5000,
    Ann100WordsPrice: 5000,
  },
  {
    id: "24",
    Jingle60SecPrice: 5000,
    Jingle45SecPrice: 5000,
    Jingle30SecPrice: 5000,
    Jingle15SecPrice: 5000,
    Ann75WordsPrice: 5000,
    Ann50WordsPrice: 5000,
    Ann100WordsPrice: 5000,
  },
];
