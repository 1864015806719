import { ChakraProvider, Box, useColorModeValue, extendTheme, useDisclosure } from '@chakra-ui/react';
import SidebarContent from './SidebarContent';
import MobileNav from './MobileNav';
import { Drawer, DrawerContent } from '@chakra-ui/react';



const breakpoints = {
    sm: "320px",
    md: "768px",
    lg: "960px",
    xl: "1200px",
    "2xl": "1536px",
};

const theme = extendTheme({ breakpoints });

const Layout = ({children}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <ChakraProvider theme={theme}>
            <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')} pos="relative" fontFamily="Telegraf">
                <SidebarContent onClose={onClose} display={{ base: 'none', md: 'block' }} />
                <Drawer
                    isOpen={isOpen}
                    placement="right"
                    onClose={onClose}
                    returnFocusOnClose={false}
                    onOverlayClick={onClose}
                    size="full"
                >
                    <DrawerContent>
                        <SidebarContent onClose={onClose} />
                    </DrawerContent>
                </Drawer>
                <MobileNav onOpen={onOpen} />
                <Box ml={{ base: 0, md: '100px' }} p="4">
                    {children}
                </Box>
            </Box>
        </ChakraProvider>
    );
}

export default Layout